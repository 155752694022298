import { Injectable } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  PopoverController,
  ToastController,
} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  loading: any;
  toast: any;
  popover: any;
  subBack: any;
  modal: any;
  alert: any;

  constructor(
    public platform: Platform,
    private _toastController: ToastController,
    private _modalController: ModalController,
    private _loadingController: LoadingController,
    private _popoverController: PopoverController,
    private _alertController: AlertController
  ) {}

  ////////////////////////// TOAST - NATIVE //////////////////////////////

  async showToast(
    msg: string,
    dur = 3000,
    status: number = 200,
    positionToast: ('top' | 'bottom' | 'middle') = 'top',
    classToast = 'toastNotification'
  ) {
    if (this.toast) await this.toast.dismiss();

    let color: string;
    if (status === 350) color = 'text-pending';
    else color = status === 200 ? 'success-new': 'danger-new';

    this.toast = await this._toastController.create({
      cssClass: classToast,
      message: msg,
      position: status === 200 ? positionToast: 'bottom',
      duration: dur,
      animated: true,
      translucent: true,
      keyboardClose: true,
      mode: 'md',
      color: color,
    });
    return this.toast.present();
  }

  async hideToast() {
    if (this.toast) await this.toast.dismiss();
  }

  ////////////////////////// LOADING - NATIVE //////////////////////////////

  async showLoading(
    message: string = 'Espere...',
    cssClass: string = '',
    spinner: 'dots' | 'circles' = 'dots',
    duration?: number,
    backdropDismiss: boolean = false
  ) {

    let props: any = {
      message: message,
      spinner: 'dots',
      translucent: true,
      cssClass: cssClass,
      backdropDismiss: backdropDismiss
    }

    if (duration) props[ 'duration' ] = duration;

    this.loading = await this._loadingController
      .create(props)
      .then(async (res) => {
        this.subBack = this.platform.backButton.subscribeWithPriority(
          9999,
          (processNextHandler) => {}
        );

        res.onDidDismiss().then((d) => {
          this.subBack.unsubscribe();
        });

        return res;
      });

    return await this.loading.present();
  }

  async hideLoading() {
    if (this.loading) {
      this.loading.dismiss();
      if (this.subBack) {
        this.subBack.unsubscribe();
      }
    }
  }

  ////////////////////////// ALERT - NATIVE //////////////////////////////

  async showAlert(
    msg: string | null,
    retFalse = 'Ok',
    cssClass: string | null = null
  ) {
    return new Promise(async (resolve) => {
      let opts: any = {
        message: msg,
        backdropDismiss: false,
        buttons: [
          {
            text: retFalse,
            handler: () => {
              resolve(false);
            },
          },
        ],
      };
      if (cssClass) {
        opts['cssClass'] = cssClass;
      }

      this.alert = await this._alertController.create(opts);
      await this.alert.dismiss();
      await this.alert.present();
    });
  }

  async hideAlert() {
    if (this.alert) await this.alert.dismiss();
  }

  async showConfirm(
    msg: string | null,
    retTrue = 'Continuar',
    retFalse = 'Cancelar',
    cssClass: string | null = null
  ) {
    return new Promise(async (resolve) => {
      let opts: any = {
        message: msg,
        backdropDismiss: false,
        buttons: [
          {
            text: retTrue,
            handler: () => {
              resolve(true);
            },
          },
          {
            text: retFalse,
            handler: () => {
              resolve(false);
            },
          }
        ],
      };
      if (cssClass) {
        opts['cssClass'] = cssClass;
      }

      if (this.alert) this.alert.dismiss();

      this.alert = await this._alertController.create(opts);
      await this.alert.present();
    });
  }

  ////////////////////////// MODAL - NATIVE //////////////////////////////

  async showModal(
    ModalComponent: any, // Function | HTMLElement | null | string,
    cssClass?: string,
    animated?: any,
    backdropDismiss: boolean = false,
    componentProps?: any,
    keyboardClose?: boolean,
    showBackdrop?: boolean,
    swipeToClose?: boolean,
    mode: 'ios' | 'md' = 'md'
  ) {
    if (this.modal) this.modal.dismiss();

    this.modal = await this._modalController.create({
      component: ModalComponent,
      cssClass: cssClass,
      animated: animated,
      backdropDismiss: backdropDismiss,
      componentProps: componentProps,
      keyboardClose: keyboardClose,
      showBackdrop: showBackdrop,
      mode: mode,
    });

    await this.modal.present();
  }

  async hideModal(dataSend?: any) {
    if (this.modal) {
      await this.modal.dismiss(dataSend);
      const { data } = await this.modal.onWillDismiss();
      return data;
    }
  }

  ////////////////////////// POPOVER - NATIVE //////////////////////////////

  async showPopover(
    PopoverComponent: any, // Function | HTMLElement | null | string,
    cssClass?: string,
    ev?: any,
    translucent: any = true,
    animated?: any,
    backdropDismiss: boolean = false,
    componentProps?: any,
    keyboardClose?: boolean,
    showBackdrop?: boolean,
    mode: 'ios' | 'md' = 'md'
  ) {
    if (this.popover) this.popover.dismiss();

    this.popover = await this._popoverController.create({
      component: PopoverComponent,
      cssClass: cssClass,
      event: ev,
      translucent: translucent,
      animated: animated,
      backdropDismiss: backdropDismiss,
      componentProps: componentProps,
      keyboardClose: keyboardClose,
      showBackdrop: showBackdrop,
      mode: mode,
    });
    await this.popover.present();
  }

  async hidePopover() {
    if (this.popover) this.popover.dismiss();
  }
}
