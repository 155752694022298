import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, first, map, Observable, pairwise, startWith } from 'rxjs';
import { ModuleBackend, TypeRequestList } from '@enums/index';
import { GenericCrudService } from '@common/classes/generic-crud.service';
import { IPagination, ICrudResponse, IResponse, IOrderRequest, ISupplier, ISupplierQuotation } from '@interfaces/*';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class OrderRequestService extends GenericCrudService<IOrderRequest> {
  private typeRequestList$: BehaviorSubject<TypeRequestList>;
  private requestSelected$: BehaviorSubject<IOrderRequest>;

  constructor() {
    super(ModuleBackend.PURCHARSER_ORDERS);
    this.typeRequestList$ = new BehaviorSubject(TypeRequestList.POR_COTIZAR);
    this.requestSelected$ = new BehaviorSubject(null);
  }

  override create(data: Partial<IOrderRequest>): Observable<IResponse<IOrderRequest>> { return super.create(data); }
  createSupplierQuotation(data: FormData): Observable<IResponse<ISupplierQuotation>> {
    return this.http.post<IResponse<ISupplierQuotation>>(`
    ${environment.API_URL}/${ModuleBackend.PURCHARSER_ORDERS}/supplier/create`,
      data,
      { headers: this.headerComplement.getHeadersUploadFile() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }
  override readOne(id: number): Observable<IResponse<IOrderRequest>> { return super.readOne(id); }
  override readAll(pagination: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {} | {}[]): Observable<ICrudResponse<IOrderRequest>> { return super.readAll(pagination, extraKeys); }
  readAllSuppliers(): Observable<IResponse<ISupplier[]>> {
    return this.http.get<IResponse<ISupplier[]>>(`
      ${environment.API_URL}/${ModuleBackend.ACCOUNTING_AUXILIARY}/auxiliar/type?typeAuxiliaryName=Proveedores`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }
  override update(id: number, data: Partial<IOrderRequest>): Observable<IResponse<IOrderRequest>> { return super.update(id, data); }
  updateStatus(id: number, data: { status: TypeRequestList, description: string }): Observable<IResponse<IOrderRequest>> {
    return this.http.patch<IResponse<IOrderRequest>>(`
    ${environment.API_URL}/${ModuleBackend.PURCHARSER_ORDERS}/${id}/change-status`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }
  updateSupplierQuotationStatus(id: number, status: { status: boolean }): Observable<IResponse<ISupplierQuotation>> {
    return this.http.patch<IResponse<ISupplierQuotation>>(`
    ${environment.API_URL}/${ModuleBackend.PURCHARSER_ORDERS}/supplier/status/${id}`,
      status,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }
  deleteSupplierQuotation(id: number): Observable<IResponse<ISupplierQuotation>> {
    return this.http.delete<IResponse<ISupplierQuotation>>(`
    ${environment.API_URL}/${ModuleBackend.PURCHARSER_ORDERS}/supplier/delete/${id}`,
      { headers: this.headerComplement.getHeadersUploadFile() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  setTypeRequestList(type: TypeRequestList) { this.typeRequestList$.next(type); }
  getTypeRequestList() { return this.typeRequestList$.getValue(); }
  getTypeRequestListObservable(): Observable<{ previousState: TypeRequestList; currentState: TypeRequestList; }> {
    return this.typeRequestList$.asObservable()
      .pipe(
        startWith(TypeRequestList.NONE),
        pairwise(),
        map(([previousState, currentState]) => ({ previousState, currentState }))
      );
  }
  setRequest(request: IOrderRequest) { this.requestSelected$.next(request); }
  getRequest() { return this.requestSelected$.getValue(); }
}
