import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, first, map } from 'rxjs';
import { HttpComplement } from '@common/functions/http-complement';
import { ModuleBackend } from '@enums/index';
import { IResponse } from '@interfaces/index';


@Injectable({
  providedIn: 'root'
})
export class ProfileClientService {

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) { }

  readCurrentTokenFromCheckStatus(): Observable<IResponse<any>> {
    return this.http.get<IResponse<any>>(`
    ${environment.API_URL}/${ModuleBackend.AUTH_SAE}/check_status`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  readCurrentVersion(): Observable<IResponse<any>> {
    return this.http.get<IResponse<any>>(`
      ${environment.API_URL}/${ModuleBackend.VERSION}/current`,
    )
    .pipe(first());
  }

  update(data: Partial<any>): Observable<IResponse<any>> {
    return this.http.post<IResponse<any>>(`
    ${environment.API_URL}/${ModuleBackend.USER_SAE_CLIENT}/update_profile`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  updateProfile(module: ModuleBackend,data: Partial<any>, id: number): Observable<IResponse<any>> {
    return this.http.patch<IResponse<any>>(`
    ${environment.API_URL}/${module}/${id}`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  updateClientRateLimitDate(id: number, data: { rateLimitDate: number }): Observable<IResponse<any>> {
    return this.http.patch<IResponse<any>>(`
    ${environment.API_URL}/${ModuleBackend.CLIENTS}/client/rateLimitDate/${id}`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  readFileFromCurrentToken(): Observable<IResponse<{ iconProfile: { id: number, file: string } }>> {
    return this.http.get<IResponse<{ iconProfile: { id: number, file: string } }>>(`
    ${environment.API_URL}/${ModuleBackend.AUTH_SAE}/current_token`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  updateFile(data: { file: string }): Observable<IResponse<any>> {
    return this.http.post<IResponse<any>>(`
    ${environment.API_URL}/${ModuleBackend.AUTH_SAE}/profile_photo`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }
}
