import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPagination } from './interface';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})

export class PaginationComponent implements OnInit {

  @Input() pagination!: IPagination;
  @Output() fisrtPage?: any = new EventEmitter<number>();
  @Output() prevPage?: any = new EventEmitter<number>();
  @Output() nextPage?: any = new EventEmitter<number>();
  @Output() lastPage?: any = new EventEmitter<number>();
  @Output() changePage?: any = new EventEmitter<number>();
  @Output() elementsPerPage: any = new EventEmitter<number>();

  ionViewWillEnter() {
  console.log('pagination', this.pagination);

  }

  ngOnInit() {
    console.log('pagination', this.pagination);
  }

  goToFirtsPage(){
    if(this.pagination) {
      this.fisrtPage?.emit(1);
      this.changePage?.emit(1);
    }
  }

  goToNextPage(){
    if(this.pagination){
      this.nextPage?.emit(this.pagination.currentPage + 1);
      this.changePage?.emit(this.pagination.currentPage + 1);
    }
  }

  goToPage(currentPage: any) {
    this.changePage?.emit(currentPage);
  }

  goToPrevPage(){
    if(this.pagination){
      this.prevPage?.emit(this.pagination.currentPage - 1);
      this.changePage?.emit(this.pagination.currentPage - 1);
    }
  }

  goToLastPage(){
    if(this.pagination){
      this.prevPage?.emit(this.pagination.totalPages);
      this.changePage?.emit(this.pagination.totalPages);
    }
  }

  changeItemsPerPage(ev: any){
    if(ev){
      this.elementsPerPage.emit(ev.detail.value);
    } 
  }

}
