import { Component, OnInit } from '@angular/core';
import { NavigationApp } from '@navigation/*';
import { LocalStorageService } from '@services/common';
import { Capacitor } from '@capacitor/core';
import { ActivationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  
  navigationApp = NavigationApp;
  inWeb: boolean = false;
  infoClientSelected: any;
  routesSelected$: any;
  activeRoute: any;
  

  constructor(
    private _router: Router,
    private _localStorage: LocalStorageService
  ){
    this.infoClientSelected = null;
    if(Capacitor.getPlatform() === 'web' && window.innerWidth > 768){
      this.inWeb = true;
    }
  }

  ngOnInit(){
    this.routesSelected$ = this.getRoutesSelected().subscribe((data) => {
      this.activeRoute = data['routes']
    });
    this.getClient();
  }

  getRoutesSelected() {
    return this._router.events.pipe(
      filter((event: any) => event instanceof ActivationEnd),
      filter((event: ActivationEnd) => event.snapshot.firstChild === null),
      map((event: ActivationEnd) => event.snapshot.data)
    )
  }

  getClient() {
    this.infoClientSelected = this._localStorage.getItem('userSession').data?.infoClientSelected?.client;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.routesSelected$.unsubscribe();
  }
}
