import { Injectable } from '@angular/core';
import { catchError, first, Observable, of } from 'rxjs';
import { ModuleBackend } from '@enums/index';
import { ICrudResponse, IFilter, IInventory, IPagination, IResponse, ITypeInventory } from '@interfaces/index';
import { GenericCrudService } from '@common/classes/generic-crud.service';
import { TableTypesService } from '@services/common';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class InventoryService extends GenericCrudService<IInventory> {
  readonly filterObj: IFilter[] = [];
  private readonly inventoryURL: ModuleBackend;

  constructor(private tableTypesService: TableTypesService) {
    // const inventoryURL = (ModuleBackend.WAREHOUSES + '/' + ModuleBackend.INVENTORY) as ModuleBackend;
    const inventoryURL = ModuleBackend.WAREHOUSES;
    super(inventoryURL);
    // this.inventoryURL = inventoryURL;
  }

  // override create(data: Partial<IInventory>): Observable<IResponse<IInventory>> { return super.create(data); }
  // override readOne(id: number): Observable<IResponse<IInventory>> { return super.readOne(id); }
  override readAll(pagination?: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {} | {}[]): Observable<ICrudResponse<IInventory>> { return super.readAll(pagination, extraKeys); }
  readAllTypeInventory(): Observable<ITypeInventory[]> { return this.tableTypesService.readAllTypeInventory(); }
  // override update(id: number, data: Partial<IInventory>): Observable<IResponse<IInventory>> { return super.update(id, data); }
  // override delete(id: number): Observable<IResponse<IInventory>> { return super.delete(id); }
}
