import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;

    // Eliminar todos los caracteres no numéricos
    value = value.replace(/\D/g, '');

    let formattedValue = '';
    if (value.length > 0) {
      formattedValue = value.substring(0, 4);
    }
    if (value.length > 4) {
      formattedValue += '-' + value.substring(4, 7);
    }
    if (value.length > 7) {
      formattedValue += '-' + value.substring(7, 9);
    }
    return formattedValue;
  }

}
