import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BillingService {
  private showCreateRequest$: BehaviorSubject<boolean>;
  private showCreateQuotation$: BehaviorSubject<boolean>;

  constructor() {
    this.showCreateRequest$ = new BehaviorSubject(false);
    this.showCreateQuotation$ = new BehaviorSubject(false);
  }

  setshowCreateRequest(show: boolean = false) { this.showCreateRequest$.next(show); }
  getshowCreateRequest() { return this.showCreateRequest$.getValue(); }
  setshowCreateQuotation(show: boolean = false) { this.showCreateQuotation$.next(show); }
  getshowCreateQuotation() { return this.showCreateQuotation$.getValue(); }
}
