import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private stateSource = new BehaviorSubject<any>(null);
  state$ = this.stateSource.asObservable();

  dataHistory: any;

  setState(newState: any) {
    this.dataHistory = newState;
    this.stateSource.next(newState);
  }

  getState() {
    return this.dataHistory;
  }
}
