import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModuleBackend } from '@enums/index';
import { ICrudResponse, IModulesSae, IPagination, IResponse, IRoleSae } from '@interfaces/index';
import { GenericCrudService } from '@common/classes/generic-crud.service';


@Injectable({
  providedIn: 'root'
})
export class RolesService extends GenericCrudService<IModulesSae | IRoleSae> {

  constructor() { super(ModuleBackend.ROLES_SAE_CLIENT); }

  override create(data: Partial<IRoleSae>): Observable<IResponse<IRoleSae>> { return super.create(data) as Observable<IResponse<IRoleSae>>; }
  override readOne(id: number): Observable<IResponse<IRoleSae>> { return super.readOne(id) as Observable<IResponse<IRoleSae>>; }
  override readAll(pagination: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {} | {}[]): Observable<ICrudResponse<IModulesSae>> { return super.readAll(pagination, extraKeys) as Observable<ICrudResponse<IModulesSae>>; }
  override update(id: number, data: Partial<IModulesSae | IRoleSae>): Observable<IResponse<IRoleSae>> { return super.update(id, data) as Observable<IResponse<IRoleSae>>; }
  override delete(id: number): Observable<IResponse<IRoleSae>> { return super.delete(id) as Observable<IResponse<IRoleSae>>; }
}
