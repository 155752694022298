import { Injectable } from '@angular/core';
import { LocalStorageService } from '.';
import { File as FileOriginal } from '@awesome-cordova-plugins/file';
import { FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { environment } from '@environments/environment';
import { constants } from '@common/index';
import { FileUploadOptions } from '@awesome-cordova-plugins/file-transfer/ngx';


@Injectable({
  providedIn: 'root'
})
export class FileManagerService {

  private userSession = {} as any;
  file!: typeof FileOriginal;
  fileTransfer: FileTransferObject;

  constructor(
    private _localStorage: LocalStorageService,
    
  ) { 
    this.userSession = this._localStorage.getItem('userSession')?.data;
    this.file = FileOriginal;
    this.fileTransfer = new FileTransferObject();
  }
  /**
   * Downloads a file from the specified module and saves it to the specified directory with the specified name and format.
   *
   * @param {string} module - the module to download the file from
   * @param {string} [directory='Download/'] - the directory to save the file to (default is 'Download/')
   * @param {string} fileName - the name of the file to be saved
   * @param {string} format - the format of the file to be saved
   * @return {Promise<any>} a promise that resolves when the file is downloaded successfully, or rejects with an error
   */
  download(module: string, directory: string = 'Download/', fileName: string, format: string) {
    var token = this.userSession.token;
    var path = this.file.externalRootDirectory + directory + fileName + '.' + format;
    
    var url = encodeURI(`${environment.API_URL}/${module}`);
    
    return this.fileTransfer.download(
      url,
      path,
      false,
      {
        headers: {
          "authorization": 'Bearer ' + token
        }
      })
  }

  /**
   * Uploads a file to the specified endpoint.
   *
   * @param {string} endpoint - The URL endpoint to upload the file to.
   * @param {any} file - The file to be uploaded.
   * @param {any} [param=''] - The optional parameter for the upload.
   * @return {Promise<any>} - A Promise that resolves with the upload result.
   */
  upload(endpoint: string, file: any, param: any = '') {
    let options: FileUploadOptions = {
      fileKey: 'file',
      fileName: file.name,
      mimeType: constants.MIME_TYPE_EXCEL,
      chunkedMode: false,
    }
    var token = this.userSession.token;
    endpoint = encodeURI(`${environment.API_URL}/${endpoint}`);

    options.headers = {
      "authorization": 'Bearer ' + token
    }
    if (param) {
      options.params = {
        'compoundId': param
      }
    }
    
    return this.fileTransfer.upload(
      file.path,
      endpoint,
      options
    );
  }
}
