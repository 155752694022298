import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class KycService {

  private API_URI: string;
  

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  KYCInformation(Module: ModuleBackend, data: any) {
    return this.http.post(
      `${this.API_URI}/${Module}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

}
