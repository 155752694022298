export enum ServiceName {
  user = 'Usuario',
  calendar = 'Calendario',
  notification = 'Notificaciones', // TODO rename ServiceName.notification to ServiceName.billboard
  survey = 'Encuestas',
  payments_and_receipts = 'Pagos y Recibos',
  employee = 'Empleados',
  enclosure = 'Recintos',
  property = 'Inmuebles',
  accounting = 'Contabilidad'
}