import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-notifiy-alert',
  templateUrl: './notifiy-alert.component.html',
  styleUrls: ['./notifiy-alert.component.scss'],
})
export class NotifyAlertComponent {

  @Input() icon!: string;
  @Input() textBtn!: any;
  @Input() title!: string;
  @Input() subtitle!: any;
  @Input() subtitle1!: any;
  @Input() subtitle2!: any;
  @Input() subtitle3!: any;
  @Input() subtitle4!: any;
  @Input() subtitle5!: any;
  @Input() classContent!: any;
  @Input() classAction0!: any;
  @Input() classAction1!: any;
  @Input() classRowAction!: any;
  @Input() formArrayLabel!: any;
  @Input() actionButton!: boolean;
  @Input() isActionCheckMultiple!: any;

  @Output() deleteChange = new EventEmitter<boolean>();
  @Output() closeModal = new EventEmitter<boolean>();

  constructor(
    private modalController: ModalController
  ) {}


  EmitAction(name: any) {
    let data: any = null;
    if (this.formArrayLabel == null || !this.formArrayLabel) data = true;
    else data = this.formArrayLabel;
    this.modalController.dismiss(data, name);
  }

  checkSelected() {
    let disabledForm: any = false;
    this.formArrayLabel.map((ty: any, index: number): any => {
      if (ty.checked) return disabledForm = true;
    });

    return disabledForm;
  }


  getCheck(event: any, item: any, index: any) {
    this.formArrayLabel[index].checked = event.checked;
  }

  Close() {
    this.modalController.dismiss();
  }

}
