import { HttpHandler, HttpRequest, HttpInterceptor } from '@angular/common/http';
import { HttpComplement } from 'src/common/functions/http-complement';
import { LocalStorageService, MessagesService } from '../services';
import { NavigationApp } from 'src/navegation';
import { catchError, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsFunctions } from '@common/functions/utils';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    public utilsFunctions: UtilsFunctions,
    private headerComplement: HttpComplement,
    private _messagesService: MessagesService,
    private _localStorage: LocalStorageService,
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error.error.statusCode === 401 && (!error.url.includes('check_bill_st') && !error.url.includes('recover_pas'))) {
          this._localStorage.deleteItem('userSession');
          this._messagesService.hideToast();
          this._messagesService.hideLoading();
          this.utilsFunctions.presentToast(error.error.message,"danger", 1500,'bottom');
          this.router.navigate([NavigationApp.LOGIN], this.headerComplement.extrasNavigation());
        }
        throw error;
      })
    );
  }

}
