<ion-row 
  [ngClass]="{'height-dinamic': activeSubMenu }" 
  class="row-dinamic-float">
  <ion-col *ngIf="rootDinamic === 'SERVICES'" size="12">
    <div>
      <ng-container *ngFor="let service of services; let i = index">
        <li *ngIf="(!itemSelectSubMenuList || itemSelectSubMenuList.startsWith('SERVICES' + i))"
            [ngClass]="{'not-icon-list': itemSelectSubMenuList === ('SERVICES' + i) }">
          <span 
            (click)="!service.link ? selectSubItem('SERVICES', i) : redirect(service.link)"
            [ngClass]="{'d-none': itemSelectSubMenuList === ('SERVICES' + i) }">
            {{service.name}}
          </span>

          <div *ngIf="!loadingModules && itemSelectSubMenuList && itemSelectSubMenuList.startsWith('SERVICES' + i) && !service?.link" class="item-expand">
            <ion-row *ngIf="!itemSelectSubMenuList.startsWith('SERVICES' + i + '-')" (click)="resetItemSelectSubMenuList()">
              <ion-icon name="arrow-back-outline"></ion-icon>
              <ion-text>{{service.name}}</ion-text>
            </ion-row>
            <ng-container *ngFor="let subitem of service.subitems; let j = index">
              <li *ngIf="!subitem.subitems" (click)="onDocumentClick()" 
                [routerLink]="subitem.link">{{subitem.name}}</li>
              <li *ngIf="subitem.subitems && !itemSelectSubMenuList.startsWith('SERVICES' + i + '-' + j)" 
                (click)="selectSubSubItem('SERVICES' + i, j)">
                {{subitem.name}}
              </li>

              <div *ngIf="itemSelectSubMenuList === ('SERVICES' + i + '-' + j) && subitem?.subitems" class="item-expand pl-md">
                <ion-row (click)="selectSubItem('SERVICES', i)">
                  <ion-icon name="arrow-back-outline"></ion-icon>
                  <ion-text>{{subitem.name}}</ion-text>
                </ion-row>
                <ng-container *ngFor="let subsubitem of subitem.subitems">
                  <li (click)="gotoForm(subsubitem.link,subitem,subsubitem)">{{subsubitem.name}}</li>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <div class="content-center" *ngIf="loadingModules">
            <ion-item class="loading-children" lines="none">
              <ion-spinner></ion-spinner>
              <ion-text>Cargando...</ion-text>
            </ion-item>
          </div>
        </li>
      </ng-container>
    </div>
  </ion-col>

  <ion-col *ngIf="rootDinamic === 'USERS' && userRoutes?.length" size="12">
    <ion-row>Usuarios</ion-row>
    <ul>
      <ng-container *ngFor="let route of userRoutes">
        <li (click)="onDocumentClick()" [routerLink]="route.link">{{route.name}}</li>
      </ng-container>
    </ul>
  </ion-col>

  <ion-col *ngIf="rootDinamic === 'ROLES'" size="12">
    <ion-row>Roles y Permisos</ion-row>
    <ul>
      <ng-container *ngFor="let route of roleRoutes">
        <li (click)="onDocumentClick()" [routerLink]="route.link">{{route.name}}</li>
      </ng-container>
    </ul>
  </ion-col>

  <ion-col *ngIf="rootDinamic === 'search'" size="12">
    <ion-input
      class="input-search-menu-floating"
      [placeholder]="searchPlaceholder"
      mode="md"
      (keyup.enter)="onDocumentClick()"
      maxlength="50">
    </ion-input>
  </ion-col>
</ion-row>
