import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { ModuleBackend } from '@enums/index';
import { GenericCrudService } from '@common/classes/generic-crud.service';
import { ICrudResponse, IPagination, IPurchaseOrder } from '@interfaces/*';


@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService extends GenericCrudService<IPurchaseOrder> {
  constructor() {
    super(ModuleBackend.ORDERS_CLIENT);
  }

  override readAll(pagination: Pick<IPagination, "currentPage" | "skip">, extraKeys?: {} | {}[]): Observable<ICrudResponse<IPurchaseOrder>> { return super.readAll(pagination, extraKeys); }
}
