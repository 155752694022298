import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { environment } from '@environments/environment';
import { IPagination } from '@common/components/pagination/interface';


@Injectable({
  providedIn: 'root'
})
export class CurrenciesService {

  private API_URI: string;

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  getCurrencies(Module: ModuleBackend, pagination: IPagination){
    return this.http.get(
      `${this.API_URI}/${Module}?page=${pagination.currentPage}&skip=${pagination.skip}`,
      this.headerComplement.complementHeader()
    );
  }

  getCurrenciesWithId(Module: ModuleBackend, id: any){
    return this.http.get(
      `${this.API_URI}/${Module}/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  updateCurrenciesRate(Module: ModuleBackend){
    return this.http.get(
      `${this.API_URI}/${Module}/updates/rate`,
      this.headerComplement.complementHeader()
    );
  }

}
