import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthType } from '@enums/authTypeUser.enum';
import { LocalStorageService } from '@services/common';
import { environment } from 'src/environments/environment';
import { NavigationApp } from 'src/navegation';

@Injectable({
  providedIn: 'root'
})
export class ValidateAuthValidateAccessGuard implements CanActivate {

  API_URI: string = environment.API_URL

  constructor(
    public router: Router,
    private _localStorage: LocalStorageService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {

    if (this._localStorage.getItem('userSession') !== null && this._localStorage.getItem('userSession').data && (this._localStorage.getItem('userSession').data.firstAccess)) {
      return true;
    } else {
      this.router.navigate([NavigationApp.LOGIN]);
      return false;
    }
  }


}
