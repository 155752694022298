import { HttpComplement } from 'src/common/functions/http-complement';
import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPagination, IUser, IUsers } from '../interfaces';
import { Observable } from 'rxjs';
import { UrlTree } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class NotificationPushService {

  private API_URI: string;

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  sendPushNotification(module: ModuleBackend, data: any): Observable<boolean | UrlTree> | any {
    return this.http.post(`
    ${this.API_URI}/${module}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  sendNotification(module: ModuleBackend, data: any): Observable<boolean | UrlTree> | any {
    return this.http.post(`
    ${this.API_URI}/${module}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  getAllNotificationAdmin(module: ModuleBackend, pagination: IPagination): Observable<boolean | UrlTree> | any {
    return this.http.get(`
      ${this.API_URI}/${module}/general?page=${pagination.currentPage}&skip=${pagination.skip}`,
      this.headerComplement.complementHeader()
    );
  }

  getAllNotificationUserSae(module: ModuleBackend, pagination: IPagination): Observable<boolean | UrlTree> | any {
    return this.http.get(`
      ${this.API_URI}/${module}?page=${pagination.currentPage}&skip=${pagination.skip}`,
      this.headerComplement.complementHeader()
    );
  }

  getNotificationById(module: ModuleBackend, id: number): Observable<boolean | UrlTree> | any {
    return this.http.get(`
      ${this.API_URI}/${module}/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  updateNotificationById(module: ModuleBackend, id: number, data: any): Observable<boolean | UrlTree> | any {
    return this.http.patch(`
      ${this.API_URI}/${module}/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  deleteById(module: ModuleBackend, id: number): Observable<boolean | UrlTree> | any {
    return this.http.delete(`
      ${this.API_URI}/${module}/${id}`,
      this.headerComplement.complementHeader()
    );
  }

}
