import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'modal-notification-push',
  templateUrl: './modal-notification-push.component.html',
  styleUrls: ['./modal-notification-push.component.scss'],
})
export class ModalNotificationPushComponent  implements OnInit {

  @Input() visible: boolean = false;
  @Input() notifications: any[] = [];
  @Output() dismissModal: EventEmitter<boolean> = new EventEmitter(); 
  clickNotif: number = -1;

  constructor(
    private modalCtrl: ModalController 
  ) { }

  ngOnInit() {}

  closeModal(){
    console.log('si se llama')
    this.modalCtrl.dismiss();
    this.dismissModal.emit(false);
  }

  changeBreakPoint(ev: any){
    // ev.detail.breakpoint
    if(ev.detail.breakpoint <= 0){
      this.dismissModal.emit(false);
    }
  }

  showMore(i: number){
    if(this.clickNotif === i){
      this.clickNotif = -1
    } else this.clickNotif = i
  }

}
