import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModuleBackend } from '@enums/index';
import { ICrudResponse, IModules, IPagination } from '@interfaces/index';
import { GenericCrudService } from '@common/classes/generic-crud.service';


@Injectable({
  providedIn: 'root'
})
export class ModulesService extends GenericCrudService<IModules> {

  constructor() { super(ModuleBackend.MODULE_SAE_CLIENT); }
  override readAll(pagination: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {} | {}[]): Observable<ICrudResponse<IModules>> { return super.readAll(pagination, extraKeys); }
}
