import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, first } from 'rxjs';
import { HttpComplement } from '@common/functions/http-complement';
import { ModuleBackend } from '@enums/index';
import { IArrear, ICrudResponse, IPagination, IPaymentReceipt, IReceipts, IResponse } from '@interfaces/index';


@Injectable({
  providedIn: 'root'
})
export class AdminReceiptsService {

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) { }

  readOne(id: number): Observable<IResponse<IReceipts>> {
    return this.http.get<IResponse<IReceipts>>(`
      ${environment.API_URL}/${ModuleBackend.ADMIN_RECEIPTS}/${id}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  readAll(pagination: Pick<IPagination, "currentPage" | "skip">, extraKeys: {} | {}[] = {}): Observable<ICrudResponse<IReceipts>> {
    const filterKeys = this.filterKeys(extraKeys);
    return this.http.get<ICrudResponse<IReceipts>>(`
        ${environment.API_URL}/${ModuleBackend.ADMIN_RECEIPTS}?page=${pagination.currentPage}&skip=${pagination.skip}${filterKeys}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  readAllPayments(pagination: Pick<IPagination, "currentPage" | "skip">, extraKeys: {} | {}[] = {}): Observable<ICrudResponse<IPaymentReceipt>> {
    const filterKeys = this.filterKeys(extraKeys);
    return this.http.get<ICrudResponse<IPaymentReceipt>>(`
        ${environment.API_URL}/${ModuleBackend.ADMIN_RECEIPTS_PAYMENTS}?page=${pagination.currentPage}&skip=${pagination.skip}${filterKeys}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  confirmReceipt(id: number): Observable<IResponse<IReceipts>> {
    return this.http.get<IResponse<IReceipts>>(`
      ${environment.API_URL}/${ModuleBackend.ADMIN_RECEIPTS}/receipt_confirm/${id}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  confirmPaymentOnReceipts(id: number): Observable<IResponse<any>> {
    return this.http.patch<IResponse<any>>(`
    ${environment.API_URL}/${ModuleBackend.ADMIN_RECEIPTS_PAYMENTS}/payment_confirm/${id}`,
      {},
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  rejectPaymentOnReceipts(id: number, rejectionReason: Partial<IPaymentReceipt>): Observable<IResponse<any>> {
    return this.http.patch<IResponse<any>>(`
    ${environment.API_URL}/${ModuleBackend.ADMIN_RECEIPTS_PAYMENTS}/payment_reject/${id}`,
      rejectionReason,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  chargeArrear(data: IArrear) {
    return this.http.post<IResponse<IArrear>>(`
    ${environment.API_URL}/${ModuleBackend.ADMIN_RECEIPTS_CHARGE}`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(first());
  }

  private filterKeys(extraKeys: {} | {}[] = {}): string {
    return Array.isArray(extraKeys) ? extraKeys.map(x => this.baseFilterKeys(x)).join('') : this.baseFilterKeys(extraKeys);
  }

  private baseFilterKeys(extraKeys: {} = {}): string {
    return Object.keys(extraKeys).map(
      x => {
        const keyValue: any = extraKeys;
        return '&' + x + '=' + keyValue[x];
      }
    ).join('');
  }
}