import { Component, Input, OnInit } from '@angular/core';
import { LoadingService } from '@services/loading.service';


@Component({
  selector: 'app-loading-route',
  templateUrl: './loading-route.component.html',
  styleUrls: ['./loading-route.component.scss'],
})
export class LoadingRouteComponent  implements OnInit {

  loading$!: any;

  constructor(
    private loadingService: LoadingService,
  ) { }

  ngOnInit() {
    this.loading$ = this.loadingService.loading$;
  }

}