import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuStateService {
  
  private rootDinamicSubject = new BehaviorSubject<string>('SERVICES');
  private activeSubMenuSubject = new BehaviorSubject<boolean>(false);
  private itemSelectSubMenuListSubject = new BehaviorSubject<string>('');

  rootDinamic$ = this.rootDinamicSubject.asObservable();
  activeSubMenu$ = this.activeSubMenuSubject.asObservable();
  itemSelectSubMenuList$ = this.itemSelectSubMenuListSubject.asObservable();

  setRootDinamic(name: string) {
    this.itemSelectSubMenuListSubject.next(null);
    this.rootDinamicSubject.next(name);
  }

  setActiveSubMenu(hasSubMenu: boolean) {
    this.activeSubMenuSubject.next(hasSubMenu);
  }

  setItemSelectSubMenuList(item: string) {
    this.itemSelectSubMenuListSubject.next(item);
  }
}
