import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PurchaseOrdersService {
  private showCreateRequest$: BehaviorSubject<boolean>;
  private showCreatePurchase$: BehaviorSubject<boolean>;
  public showCreateReceipt$: BehaviorSubject<boolean>;

  constructor() {
    this.showCreateRequest$ = new BehaviorSubject(false);
    this.showCreatePurchase$ = new BehaviorSubject(false);
    this.showCreateReceipt$ = new BehaviorSubject(false);
  }

  setshowCreateRequest(show: boolean = false) { this.showCreateRequest$.next(show); }
  getshowCreateRequest() { return this.showCreateRequest$.getValue(); }
  setshowCreatePurchase(show: boolean = false) { this.showCreatePurchase$.next(show); }
  getshowCreatePurchase() { return this.showCreatePurchase$.getValue(); }
  setshowCreateReceipt(show: boolean = false) { this.showCreateReceipt$.next(show); }
  getshowCreateReceipt() { return this.showCreateReceipt$.getValue(); }
}
