<div *ngIf="loading$ | async" class="loading-overlay">
  <div class="loading-bar"></div>
  <ion-backdrop [visible]="true"></ion-backdrop>

  <div class="loading-content">
    <img src="/assets/img/Logo-Light.svg" alt="" class="loading-image">
    <div id="box">
      <!-- <ion-spinner name="bubbles" color="tertiary-two"></ion-spinner> -->
    </div>
    <p>Cargando...</p>
  </div>

</div>
