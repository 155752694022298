import { Injectable } from '@angular/core';
import { catchError, first, Observable } from 'rxjs';
import { ModuleBackend } from '@enums/index';
import { ICrudResponse, IInvoicing, IFilter, IPagination } from '@interfaces/index';
import { GenericCrudService } from '@common/classes/generic-crud.service';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class InvoicingService extends GenericCrudService<IInvoicing> {
  readonly filterObj: IFilter[] = [];

  constructor() { super(ModuleBackend.INVOICE_SALE); }

  override readAll(pagination?: Pick<IPagination, "currentPage" | "skip">, extraKeys?: {} | {}[]): Observable<ICrudResponse<IInvoicing>> {
    return this.http.get<ICrudResponse<IInvoicing>>(`
      ${environment.API_URL}/${ModuleBackend.INVOICE_SALE}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  downloadPDF(id: number): Observable<Blob> {
    return this.http.get(`
      ${environment.API_URL}/${ModuleBackend.INVOICE_SALE}/${id}/pdf`,
      { headers: this.headerComplement.complementHeaderRaw(), responseType: 'blob' }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }
}
