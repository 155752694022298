<ion-fab #fabMenuFloating class="fab-menu-floating" slot="fixed" horizontal="center" vertical="bottom">
  <ion-grid class="menu-floating">

    <!-- Submenu Flotante -->
    <app-floating-submenu 
      [services]="services"
      [userRoutes]="userRoutes"
      [roleRoutes]="roleRoutes"
      searchPlaceholder="Buscar">
    </app-floating-submenu>

    <!-- Modulo con ruta existente y sin submenu flotante -->
    <ion-row>
      <ng-container *ngFor="let item of menuItems">
        <ion-col>
          <ion-item
            detail="false"
            lines="none"
            class="ion-text-center item-menu-floating"
            button
            [routerLink]="item.link ? item.link:null"
            (click)="changeItemMenuFloating(item.name, item.hasSubMenu)"
            [class.active]="(rootDinamic === item.name && router.url.includes(item.link)) || rootDinamic === item.name && !item.link">
            <ion-icon [src]="(rootDinamic === item.name && router.url.includes(item.link) || rootDinamic === item.name && !item.link) ? item.iconSelected : item.icon"></ion-icon>
            <ion-label *ngIf="rootDinamic === item.name && router.url.includes(item.link) || rootDinamic === item.name && !item.link" class="animate__animated animate__fadeInRight animate__faster">
              {{item.label}}
            </ion-label>
          </ion-item>
        </ion-col>
      </ng-container>
    </ion-row>
  </ion-grid>
</ion-fab>
