<div class="content-modal ion-padding" mode="md">
  <ion-title color="secondary-three" class="ion-no-padding ion-text-center title">Filtrar</ion-title>
  <ion-grid class="content-form"  *ngIf="form">
    <ion-row>
      <ion-col class="content-buttons-action button-reset">
          <ion-button fill="outline"  (click)="reset()">
            <ion-icon name="trash"></ion-icon>
            <ion-label>Limpiar Busqueda</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="content-field">
        <form [formGroup]="form" class="form-filter">
          <ion-row *ngFor="let filter of filters">
            <ion-col *ngIf="filter.type==='text'" [sizeMd]="12" [sizeSm]="12" class="content-field">
              <ion-label class="label-field">
                {{filter.label}}
              </ion-label>
              <ion-icon name="caret-forward" color="tertiary-two"></ion-icon>
              <!--<ion-item lines="none" class="form-field">-->
                <ion-input 
                  class="field-input-form"
                  inputmode="text" 
                  [formControlName]="filter.name"
                  [name]="filter.name"
                >
                </ion-input>
              <!--</ion-item>-->
            </ion-col>
            <ion-col *ngIf="filter.type==='select'" [sizeMd]="12" [sizeSm]="12" class="content-field">
              <ion-label class="label-field">
                {{filter.label}}
              </ion-label>
              <ion-icon name="caret-forward" color="tertiary-two"></ion-icon>
              <!-- <ion-item lines="none" class="form-field">-->
                <ion-select 
                  class="field-input-form"
                  placeholder="Seleccionar"
                  [formControlName]="filter.name"
                  [name]="filter.name"
                  >
                  <ion-select-option *ngFor="let item of filter.values" [value]="item.id">
                      {{item.name}}
                  </ion-select-option>
                </ion-select>
              <!--</ion-item>-->
            </ion-col>
            <ion-col *ngIf="filter.type==='radio'" [sizeMd]="12" [sizeSm]="12" class="content-field">
              <ion-label class="label-field">
                {{filter.label}}
              </ion-label>
              <ion-icon name="caret-forward" color="tertiary-two"></ion-icon>
              <ion-radio-group 
                [formControlName]="filter.name"
                class="radio-group"
              >
                <ion-radio class="field-input-form radio" *ngFor="let item of filter.values" [value]="item.id" aria-label="Custom checkbox">
                  {{item.name}}
                </ion-radio>
              </ion-radio-group>
            </ion-col>
            <ion-col *ngIf="filter.type==='date'" [sizeMd]="12" [sizeSm]="12" class="content-field">
              <ion-label class="label-field">{{filter.label}}</ion-label>
              <ion-icon name="caret-forward" color="tertiary-two"></ion-icon>
              <!--<ion-item lines="none" class="form-field dateInput">-->
                <ion-datetime-button class="field-input-form" [datetime]="filter.name"></ion-datetime-button>
                <ion-modal [keepContentsMounted]="true">
                  <ng-template>
                    <ion-datetime 
                        [id]="filter.name"
                        locale="es-US-u-hc-419-es"
                        [showDefaultButtons]="true"
                        [showClearButton]="false"
                        doneText="Ok"
                        cancelText="Cerrar"
                        [formControlName]="filter.name"
                        [name]="filter.name"
                        presentation="date">
                    </ion-datetime>
                  </ng-template>
                </ion-modal>
              <!--</ion-item>-->
            </ion-col>
          </ion-row>
        </form>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="content-buttons-action">
        <ion-button fill="outline" (click)="closeModal()">
            <ion-label>Cancelar</ion-label>
        </ion-button>
  
        <ion-button (click)="search()">
            <ion-label>Mostrar Resultados</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
