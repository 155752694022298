<ion-grid *ngIf="pagination">
  <ion-row class="container-external">
    <ion-col class="col-container">
      <ion-grid class="ion-no-padding container-numbers-page">
        <ion-row class="ion-no-padding ion-align-items-center ion-justify-content-center">
          <ion-col size="auto">
            <div>
              <ion-button class="button-arrow" (click)="goToPrevPage()" [disabled]="pagination.currentPage === 1"
                [color]="pagination.stylesButton?.color? pagination.stylesButton?.color : 'primary'"
                [shape]="pagination.stylesButton?.shape? pagination.stylesButton?.shape : 'round'"
                [fill]="pagination.stylesButton?.fill? pagination.stylesButton?.fill : 'outline'">
                 <ion-icon name="chevron-back"></ion-icon> Anterior
              </ion-button>
            </div>
          <!-- SI existen **NO MAS DE** 4 paginas (<= 4) en el paginado se mostrara la lista de todas las paginas -->
            <ng-container *ngIf="pagination.totalPages <= 4">
              <div  *ngFor="let page of [].constructor(pagination.totalPages); let i = index">
                <ion-button (click)="goToPage(i+1)" [ngClass]="{'button-number-page': i+1 == pagination.currentPage}" [fill]="i+1 === pagination.currentPage? 'outline' : 'clear'" [color]="pagination.stylesButton?.color ? pagination.stylesButton?.color : 'default'">
                  {{i+1}}
                </ion-button>
              </div>
            </ng-container>

            <!-- SI existen **MAS DE** 4 paginas ( > 4) en el paginado se mostrara SOLO la pagina actual la siguiente y la ultima -->
            <ng-container *ngIf="pagination.totalPages > 4">
              <div >
                <ion-button [color]="pagination.stylesButton?.color? pagination.stylesButton?.color : 'primary'"
                  [fill]="(pagination.currentPage === pagination.totalPages) ? 'clear' : 'solid'"
                  (click)="goToPage(pagination.currentPage === pagination.totalPages?
                  1 : pagination.currentPage === (pagination.totalPages - 1)? pagination.totalPages - 1 : pagination.currentPage)"
                >
                  {{pagination.currentPage === pagination.totalPages?
                    '1' : pagination.currentPage === (pagination.totalPages - 1)? pagination.totalPages - 1 : pagination.currentPage}}
                </ion-button>
              </div>

              <div *ngIf="pagination.currentPage !== (pagination.totalPages-1)">
                <ion-button fill="clear"
                  (click)="goToPage(pagination.currentPage === pagination.totalPages || pagination.currentPage === (pagination.totalPages - 1) ?
                  2 : pagination.currentPage + 1)"
                >
                  {{ pagination.currentPage === pagination.totalPages || pagination.currentPage === (pagination.totalPages - 1) ?
                    '2' : pagination.currentPage + 1}}
                </ion-button>
              </div>

              <div *ngIf="pagination.currentPage !== (pagination.totalPages-1)">
                <ion-button fill="clear" >
                  ...
                </ion-button>
              </div>

              <div >
                <ion-button [fill]="pagination.currentPage === pagination.totalPages? 'solid' : 'clear'"
                (click)="goToPage(pagination.totalPages)"
              >
                  {{pagination.totalPages}}
                </ion-button>
              </div>
            </ng-container>
            <div>
              <ion-button class="button-arrow" (click)="goToNextPage()" [disabled]="pagination.currentPage === pagination.totalPages"
                [shape]="pagination.stylesButton?.shape? pagination.stylesButton?.shape : 'round'"
                [fill]="pagination.stylesButton?.fill? pagination.stylesButton?.fill : 'outline'">
                Siguiente<ion-icon name="chevron-forward"></ion-icon>
              </ion-button>
            </div>
          </ion-col>

          <ion-col size="auto">
              <ion-select class="margin-top-xs" fill="outline" (ionChange)="changeItemsPerPage($event)"
                [interfaceOptions]="{ cssClass: 'popover-select' }"
                interface="popover" [value]="pagination.skip" placeholder="" color="medium">
                <ion-select-option *ngFor="let opt of pagination.pageSizeOptions" [value]="opt"><ion-text>{{opt}}</ion-text>/pág</ion-select-option>
              </ion-select>
          </ion-col>

          <ion-col *ngIf="pagination.showFirstLastButtons" size="auto">
            <ion-button class="button-arrow" (click)="goToLastPage()" [disabled]="pagination.currentPage === pagination.totalPages"
              [shape]="pagination.stylesButton?.shape? pagination.stylesButton?.shape : 'round'"
              [fill]="pagination.stylesButton?.fill? pagination.stylesButton?.fill : 'outline'">
              <ion-label>Ir a página</ion-label>
              <ion-chip [outline]="true"> {{pagination.totalPages}} </ion-chip>
                <ion-label class="text-label-go">Ir </ion-label> <ion-icon name="chevron-forward"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>
