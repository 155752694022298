import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {

  @Input() filters: any;
  form: FormGroup = new FormGroup({});

  constructor(
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController
  ){ }
  ngOnInit(): void {
    this.createForm();
  }

  createForm(){
    const formGroup: any = {};
    this.filters.forEach((item: any) => {
      formGroup[item.name] = new FormControl();
    });
    this.form = this.formBuilder.group(formGroup);
  }

  closeModal(){
    this.modalCtrl.dismiss();
  }

  search(){
    const data = this.clearFilters();
    this.modalCtrl.dismiss(data);
    this.form.reset();
  }

  clearFilters(){
      const noNull = this.form.value;
      for (const key in noNull) {
        if (noNull[key] === null)
          delete noNull[key];
      }
      return noNull;
  }

  reset(){
    this.form.reset();
  }
}
