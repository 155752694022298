import { Injectable } from '@angular/core';
import { catchError, first, Observable } from 'rxjs';
import { ICrudResponse, IErrorResponse, IPagination, IResponse } from '@interfaces/*';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { MessagesAlerts } from '@common/index';


@Injectable({
  providedIn: 'root'
})
export class MasterTablesService {
  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement,
    private messagesAlerts: MessagesAlerts
  ) { }

  create(url: string, data: any): Observable<IResponse<any>> {
    return this.http.post<IResponse<any>>(`
    ${environment.API_URL}/${url}`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  readOne(url: string, id: number): Observable<IResponse<any>> {
    return this.http.get<IResponse<any>>(`
      ${environment.API_URL}/${url}/${id}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  readAll(url: string, pagination: Pick<IPagination, "currentPage" | "skip">, extraKeys?: {} | {}[]): Observable<ICrudResponse<any>> {
    const filterKeys = this.filterKeys(extraKeys);
    return this.http.get<ICrudResponse<any>>(`
        ${environment.API_URL}/${url}?page=${pagination.currentPage}&skip=${pagination.skip}${filterKeys}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  update(url: string, id: number, data: Partial<any>): Observable<IResponse<any>> {
    return this.http.patch<IResponse<any>>(`
    ${environment.API_URL}/${url}/${id}`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  delete(url: string, id: number): Observable<IResponse<any>> {
    return this.http.delete<IResponse<any>>(`
    ${environment.API_URL}/${url}/${id}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  filterKeys(extraKeys: {} | {}[] = {}): string {
    return Array.isArray(extraKeys) ? extraKeys.map(x => this.baseFilterKeys(x)).join('') : this.baseFilterKeys(extraKeys);
  }

  private baseFilterKeys(extraKeys: {} = {}): string {
    return Object.keys(extraKeys).map(
      x => {
        const keyValue: any = extraKeys;
        return '&' + x + '=' + keyValue[x];
      }
    ).join('');
  }

  onError(error: IErrorResponse) { this.messagesAlerts.openToast(error.message, "danger", 1500, "bottom"); }
}
