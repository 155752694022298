import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationExtras } from "@angular/router";
import { LocalStorageService } from "@services/common";

@Injectable({
    providedIn: 'root',
})

export class HttpComplement {

    constructor(
      private route: ActivatedRoute,
      private _localStorage: LocalStorageService
    ) {

    }

    complementHeaderRaw(): HttpHeaders { return this.complementHeader().headers; }

    complementHeader(): any {
        let options = { headers: new HttpHeaders(this.getHeaders()) };
        return options;
    }

    getToken(): any {
      let userToken: any = localStorage.getItem('userSession');
      if (userToken && this._localStorage.getItem('userSession').data) userToken = this._localStorage.getItem('userSession').data.token;
      else userToken = null;

      if (this._localStorage.getItem('sessionFingerPrintAuth')) userToken = this._localStorage.getItem('sessionFingerPrintAuth').token;
      else if(userToken === null && !this._localStorage.getItem('sessionFingerPrintAuth')) userToken = null;

      return userToken;
    }

    getHeadersUploadFile(){
      return new HttpHeaders({
        'authorization': `bearer ${this.getToken()}`,
      });
    }

    getHeadersDownloadFile(){
      return new HttpHeaders({
        'authorization': `bearer ${this.getToken()}`,
      });
    }

    getHeaders(){
      return ({
        'Content-Type': 'application/json',
        'authorization': `bearer ${this.getToken()}`,
      });
    }

    extrasNavigation(
      StatusskipLocationChange: boolean = false,
      preserveFragment: boolean = false,
      replaceUrl: boolean = false
    ) {
      let navigationExtras: NavigationExtras = {
        skipLocationChange: StatusskipLocationChange,
        preserveFragment: preserveFragment,
        replaceUrl: replaceUrl
      };

      return navigationExtras;
    }
}
