import { NgModule } from '@angular/core';
import { NavigationApp } from 'src/navegation';
import { CheckLoginGuard } from 'src/guards/check-login.guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ValidateAuthValidateAccessGuard } from 'src/guards/check-auth-validate-access.guard';
import { StatusAppGuard } from 'src/guards/check-status-app.guard';
import { LoadPermissionsResolver } from 'src/guards/load-permissions.resolver';
import { CheckDevelopGuard } from 'src/guards/check-develop';


const routes: Routes = [
  {
    path: NavigationApp.STATUS_APP,
    loadChildren: () => import('./status-app/status-app.module').then( m => m.StatusAppPageModule)
  },
  {
    path: NavigationApp.LOGIN,
    canActivate: [StatusAppGuard, CheckLoginGuard],
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  { 
    path: NavigationApp.RESET_PASSWORD,
    canActivate: [CheckLoginGuard, StatusAppGuard],
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  { 
    path: NavigationApp.REGISTER_FROM_APP,
    canActivate: [StatusAppGuard, CheckLoginGuard],
    loadChildren: () => import('./register-from-app/register-from-app.module').then( m => m.RegisterFromAppPageModule)
  },
  {
    path: NavigationApp.DASHBOARD,
    data: {
      routes: [
        {
          title: 'Dashboard',
          routePage: `/${NavigationApp.DASHBOARD}`
        }
      ]
    },
    resolve: { data: LoadPermissionsResolver },
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'register/:uniqueCode/:clientId',
    canActivate: [StatusAppGuard, CheckLoginGuard],
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  { path: '', pathMatch: "full", redirectTo: NavigationApp.LOGIN },
  {
    path: 'first-access',
    canActivate: [ValidateAuthValidateAccessGuard],
    loadChildren: () => import('./first-access-is-Master/first-access.module').then( m => m.FirstAccessPageModule)
  },
  {
    path: 'style-control',
    canActivate: [CheckDevelopGuard],
    loadChildren: () => import('./style-control/style-control.module').then( m => m.StyleControlPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
