import { Injectable } from '@angular/core';
import { NavigationApp } from 'src/navegation';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  getItem(name: string): any {
    return JSON.parse(localStorage.getItem(name)!);
  }

  setItem(name: string, data: any): any {
    const value = JSON.stringify(data);
    localStorage.setItem(name, value);
    return JSON.parse(localStorage.getItem(name)!);
  }

  updateItem(name: string, data: any): any {
    localStorage.removeItem(name);
    localStorage.setItem(name, JSON.stringify(data));
    return JSON.parse(localStorage.getItem(name)!);
  }

  deleteItem(name: string): any {
    localStorage.removeItem(name);
  }

  clear(): void {
    localStorage.clear();
  }

  setItemUserSession(name: string, data: any) {
    let userSession: any = localStorage.getItem('userSession');
    userSession = JSON.parse(userSession);
    userSession['data'][name] = data;
    localStorage.removeItem('userSession');
    this.setItem('userSession', userSession);
  }

  getRouteRedirect() {
    const userSession = this.getItem('userSession');
    if (userSession && userSession.data && userSession.data.dashboard && userSession.data.dashboard.urlActive) {
      userSession.data.dashboard.urlActive = userSession.data.dashboard.urlActive;
      return userSession.data.dashboard.urlActive;
    }
    return `${NavigationApp.DASHBOARD}/${NavigationApp.HOME}`;
  }
}
