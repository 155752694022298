import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { constants } from '@common/index';

@Component({
  selector: 'app-successful-fullscreen',
  templateUrl: './successful-fullscreen.component.html',
  styleUrls: ['./successful-fullscreen.component.scss'],
})
export class SuccessfulFullscreenComponent implements OnChanges {
  @Input() title: string;
  @Input() saveDone: boolean;
  @Input() likeModal: boolean;
  @Output() saveDoneChange = new EventEmitter<boolean>();
  sucessfullIcon = "/assets/icon/successful/Sucessful_Icon_Animated.svg";
  constructor() {
    this.title = "¡Cambios aplicados!";
    this.saveDone = false;
    this.likeModal = false;
  }
  ngOnChanges(changes: SimpleChanges): void { if (this.saveDone) setTimeout(() => this.saveDoneChange.emit(false), constants.SUCESSFULL_SCREEN_TIMEOUT); }
}
