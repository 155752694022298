export enum ModuleBackend {
  USER = 'users',
  VERSION = 'version',
  AUTH = 'auth',
  AUTH_SAE = 'auth_sae',
  MODULE_SAE_CLIENT = "module_sae_client",
  ROLES_SAE_CLIENT = "role_sae_client",
  USER_SAE_CLIENT = "user-sae-client",
  CLIENT = "client",
  CLIENTS = "clients",
  CLIENTS_REQUEST = "clientRequest",
  EMPLOYEES = 'employees',
  PROPERTY = "property",
  COMPOUND = "compound",
  REQUEST_CLIENTS = "request-client",
  PLANS = "plans",
  PLAN = "plan",
  PLAN_CLIENTS = "plan_clients",
  PLAN_CLIENTS_SAE = "plan_clients_sae",
  CHANGE_PLAN_CLIENT = "plan_clients/change_plan_client",
  DELETE_PLAN_CLIENT = "plan_clients/remove_with_client",
  PAYMENT_METHOD = "payments-accounts",
  PAYMENTS = "payments",
  PAYMENTS_CLIENT = "payments_client",
  PAYMENTS_RECEIPT = "payments-receipt",
  RECEIPT = "receipt",
  SERVICES = "services",
  CONSULTANCY = "consultancy",
  CONTACT = "contact",
  TABLAS_TIPO = "table-types/all",
  PROPERTY_OWNER = 'propery-owner',
  PROPERTY_OWNER_CLIENT = 'property-owner-client',
  COMPOUND_CLIENT = "compound_client",
  PROPERTY_CLIENT = 'property_client',
  NOTIFICATION = 'notification',
  PUSH_NOTIFICATION = 'push-notification',
  PUSH_NOTIFICATION_CLIENT = 'push-notification-client',
  CONDITION = 'common-area-condition',
  SURVEY_ADMIN = 'survey',
  SURVEY = 'survey-response',
  EVENTS = 'event',
  EVENT_REQUEST = 'event-request',
  COMMON_AREA = 'common-area',
  COMMON_AREA_PHOTOS = 'common-area-photo',
  BIILS = 'bills',
  INVOICES = "invoices",
  BIILS_CLIENT = 'bills_client',
  CURRENCIES = 'currencies',
  PAYMENTS_TYPE_CLIENT = 'payments-type-client',
  ADMIN_RECEIPTS = 'receipt',
  ADMIN_RECEIPTS_CHARGE = 'charge-receipts',
  ADMIN_RECEIPTS_PAYMENTS = 'payments-receipt',
  MODULE_GROUPS_CLIENT = 'module_groups_client',
  ASSISTANTS_CLIENT = 'auxiliary_client',
  ACCOUNTING_ACCOUNTS = 'accounting-accounts-client',
  VOUCHER_CLIENT = 'voucherClient',
  JOURNAL_CLIENT = 'AccountingJournalClient',
  TYPE_ASSISTANTS = 'type_auxiliary_client',
  ORDER_CLIENTS = 'orders_client',
  ORDER_CLIENT = 'order_client',
  ORDERS_CLIENT = 'orders_client',
  MODULE_GROUP_CONFIG_CLIENT = 'module_group_config_client',
  MODULE_PURCHASE_ORDERS_CONFIG_CLIENT = 'purchase_orders_config_client',
  MODULE_RECEIPT_ORDERS_CONFIG_CLIENT = 'order_reception_client',
  ALIQUOTS_SCLIENT = 'aliquots_client',
  ACCOUNTING_RECEIPT = 'accounting-receipt',
  ACCOUNTING_DOCUMENTS_TYPE_CLIENT = 'accounting-document-type-client',
  RECEIPT_CLIENT = 'receipt-client',
  CLIENT_KYC = 'client_kyc',
  USER_SAE = 'user-sae',
  USER_SAE_PUBLIC = 'user-sae-public',
  WAREHOUSES = 'warehouses',
  INVENTORY = 'inventory',
  TYPE_INVENTORY = 'typeInventory',
  INVENTORY_MANAGEMENT = 'inventory-management',
  PRODUCT = 'product',
  TRANSACTION = 'transaction',
  OPERATIONS = 'operations',
  PRODUCT_PRESENTATION = 'product-presentation',
  LEVEL = 'level',
  UNIT_MEASURE = 'unit-of-measure',
  LOT = 'lot',
  ECONOMIC_ACTIVITY = 'economic-activity',
  REPORTS_CLIENT_DASHBOARD = 'reports_client/dashboard',
  PURCHARSER_ORDERS = 'purcharser-orders',
  PRICE_LIST = 'price-list',
  ACCOUNTING_AUXILIARY = 'auxiliary',
  INVOICE_REQUEST = 'invoice-request',
  INVOICE_QUOTED = 'quotations'
}
