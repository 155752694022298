import { Component, EventEmitter, Input, Output } from '@angular/core';
import { register } from 'swiper/element/bundle';

// Registro de los elementos de Swiper como Web Components
register();

@Component({
  selector: 'app-swiper-modal',
  templateUrl: './swiper-modal.component.html',
  styleUrls: ['./swiper-modal.component.scss'],
})
export class SwiperModalComponent {
  @Input() listCompounds: any[] = [];
  @Input() loading: boolean = false;
  @Input() viewModalImgFull: boolean = false;
  @Input() breakpointsDinamic: any;
  @Input() messageNoData!: string;
  @Input() size!: string;
  @Input() showNamePicture: boolean = true;
  @Input() alwaysEmitClick: boolean = false;
  @Input() imagePath: string = 'photosCompound[0]?.file';
  @Output() clickSlideEv = new EventEmitter<number>();

  compounSelected: any;
  compoundNoImage = 'assets/img/build-no-image.png';

  breakpointsSlides: any = {
    200: { slidesPerView: 2.2, spaceBetween: 10 },
    350: { slidesPerView: 3.5, spaceBetween: 10 },
    576: { slidesPerView: 4.5, spaceBetween: 10 },
    768: { slidesPerView: 6.2, spaceBetween: 15 },
    992: { slidesPerView: 5, spaceBetween: 20 },
    1200: { slidesPerView: 6.3, spaceBetween: 20 }
  };

  breakpointsSlidesVerySmall: any = {
    200: { slidesPerView: 5, spaceBetween: 10 },
    350: { slidesPerView: 8, spaceBetween: 10 },
    576: { slidesPerView: 10, spaceBetween: 10 },
    768: { slidesPerView: 14, spaceBetween: 15 },
    992: { slidesPerView: 18, spaceBetween: 20 },
    1200: { slidesPerView: 20, spaceBetween: 20 }
  };

  constructor() {
    if (this.breakpointsDinamic) {
      this.breakpointsSlides = this.breakpointsDinamic.breakpointsSlides;
      this.breakpointsSlidesVerySmall = this.breakpointsDinamic.breakpointsSlidesVerySmall;
    }
  }

  selectSlide(compound: any) {
    if (compound && !compound.selected) {
      this.listCompounds.forEach((el: any) => {
        el.selected = false;
        if (el.id === compound.id) el.selected = true;
      });

      this.compounSelected = this.listCompounds.find((el: any) => el?.selected) || null;
      this.clickSlideEv.emit(compound);
    }

    if (this.alwaysEmitClick) {
      this.clickSlideEv.emit(compound);
    }
  }

  classCompoundSelected(compound: any) {
    return compound?.selected ? 'image-compound-selected-desktop' : 'image-compound-not-selected-desktop';
  }
}
