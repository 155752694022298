import { Injectable } from "@angular/core";
import { IErrorResponse } from "@interfaces/*";
import { AlertController, IonicSafeString, ToastController } from "@ionic/angular";

@Injectable({
    providedIn: 'root',
})
export class MessagesAlerts {
    private toastMsgs: string[] = [];
    constructor(
        public toastCtrl: ToastController,
        private alertController: AlertController,
    ) { }
    /**
    * Opens a toast with the specified message, color, duration, and position.
    *
    * @param {string | string[]} message - The message to be displayed in the toast.
    * @param {string} color - The color of the toast.
    * @param {number} duration - Optional. The duration of the toast in milliseconds.
    * @param {'top'} position - Optional. The position of the toast on the screen.
    * @returns {Promise<void>} A promise that resolves when the toast is presented.
    */
    async openToast(message: string | string[], color: string, duration?: number, position: 'top' | 'middle' | 'bottom' = 'bottom') {
        let thisDuration: number;
        duration? thisDuration = duration : thisDuration = 3000;

        if (color == 'danger') position = 'bottom';

        !Array.isArray(message) ? this.openSingleToast(message, color, thisDuration, position) : this.openMultipleToast(message, color, thisDuration, position);
    }

    private async openSingleToast(message: string, color: string, duration?: number, position: 'top' | 'middle' | 'bottom' = 'top') {
        if (!message || (message && message.length == 0)) return;
        const toast = await this.toastCtrl.create({
            message: message,
            color: color,
            duration: duration ? duration : 3000,
            position: position,
        });
        toast.present();
    }

    private async openMultipleToast(message: string[], color: string, duration?: number, position: 'top' | 'middle' | 'bottom' = 'top') {
        if (!message || (message && message.length == 0)) return;
        this.toastMsgs = message;
        const toast = await this.toastCtrl.create({
            message: this.toastMsgs.toString().split(",").join(", "),
            color: color,
            duration: duration ? duration : 3000,
            position: position
        });
        toast.present();
        toast.onDidDismiss().then(() => {
            this.toastMsgs = [];
        });
    }

    onError(error: IErrorResponse) { this.openToast(error.message, "danger", 3000, "bottom"); }

}
