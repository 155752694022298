import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { SwiperComponent } from './swiper/swiper.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapModal } from './google-map-modal/google-map-modal.page';
import { SuccessfulFullscreenComponent } from './sucessful-fullscreen/successful-fullscreen.component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { NotifyAlertComponent } from './notifiyAlert/notifiy-alert.component';
import { ModalUploadBulkComponent } from './modal-upload-bulk/modal-upload-bulk.component';
import { ModalNotificationPushComponent } from './modal-notification-push/modal-notification-push.component';
import { PaginationComponent } from './pagination/pagination.component';
import { FilterComponent } from './filter/filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { PhonePipe } from 'src/pipe/phone.pipe';
import { LoadingRouteComponent } from './loading-route/loading-route.component';
import { UppercaseDirective } from '@directives/uppercase.directive';
import { MenuFloatingComponent } from './menu-floating/menu-floating.component';
import { FloatingSubmenuComponent } from './sub-menu-floating/floating-submenu.component';
import { FirstLetterPipe } from 'src/pipe/firstLetter.pipe';
import { SwiperModalComponent } from './swiper-modal/swiper-modal.component';


@NgModule({
  declarations: [
    HeaderComponent,
    LoadingScreenComponent,
    LoadingRouteComponent,
    MenuFloatingComponent,
    SwiperModalComponent,
    FloatingSubmenuComponent,
    SwiperComponent,
    FirstLetterPipe,
    GoogleMapModal,
    UppercaseDirective,
    NotifyAlertComponent,
    SuccessfulFullscreenComponent,
    ModalConfirmComponent,
    ModalUploadBulkComponent,
    ModalNotificationPushComponent,
    PaginationComponent,
    FilterComponent,
    PhonePipe
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    GoogleMapsModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic'],        // toggled buttons
          // ['blockquote', 'code-block'],
          // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          // [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
          // [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
          // [{ 'direction': 'rtl' }],                         // text direction
          // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          // [{ 'font': [] }],
          [{ 'align': [] }],
          // ['image']                         // link and image, video
        ]
      },
      theme: 'snow',
      debug: 'warn',
      placeholder: '',
    })
  ],
  exports: [
    QuillModule,
    MenuFloatingComponent,
    FloatingSubmenuComponent,
    HeaderComponent,
    LoadingScreenComponent,
    LoadingRouteComponent,
    SwiperModalComponent,
    SwiperComponent,
    GoogleMapModal,
    FirstLetterPipe,
    NotifyAlertComponent,
    UppercaseDirective,
    SuccessfulFullscreenComponent,
    ModalConfirmComponent,
    ModalUploadBulkComponent,
    ModalNotificationPushComponent,
    PaginationComponent,
    FilterComponent,
    PhonePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [PhonePipe]
})
export class CommonComponentsModule { }
