/* eslint-disable @typescript-eslint/naming-convention */
export const urlsApp = {

  /* EndPoints backend */
  USERS: '/users',
  APP_VERSION: '/apk-version',
  APP_REDIRECT: '/apk-redirect',
  USERS_ID: '/users/get-userid',
  EMAIL_RECOVERY_PASS: '/users/email-recovery-pass',
  RECOVERY_PASS: '/users/recovery-pass',
  USERS_GET_USER: '/users/get-user',
  CONTACTS: '/contacts',

  /* EndPoints OTP */
  SAVE_OTP: '/save-otp',
  VALIDATE_OTP: '/validate-otp',
  GENERATE_OTP: '/generate-otp',
  CHANGE_OTP: '/change-otp',
  SEND_CODE: '/send-code',
  SEND_CODE_OTP: '/send-code-otp',
  SEND_CODE_OTP_VALIDATE: '/send-code-otp-validate',
  SEND_CODE_PHONE: '/send-code-phone',
  CODE_VERIFICATION: '/code-verification',
  CODE_VERIFICATION_PHONE: '/code-verification-phone',

  /* EndPoints Captcha */
  REGISTER_CAPTCHA: '/captcha/register',

  /* EndPoints Authentication */
  AUTHENTICATION_FINGERPRINT: '/is-authenticated',
  AUTHENTICATE: '/authenticate',
  ALTERNATE_AUTHENTICATION: '/alternate-authentication',
  REFRESH_TOKEN: '/token',
  VERIFY_TOKEN: '/verify-token',
  LOGOUT: '/sign-off',

  /* Endpoint Settings */
  SEND_OTP_RESTORE: '/users/send-code-restore-pin',
  VALIDATE_OTP_RESTORE: '/users/validate-code-phone',
  UPDATE_USER: '/users/update-user',
};
