import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MenuStateService } from '@services/common';
import { Observable, Subscription } from 'rxjs';
import { MessagesAlerts } from '@common/index';
import { ModuleBackend } from '@enums/modules-backend.enum';
import { ModulesManagementService } from '@services/accountingServices';
import { StateService } from '@services/common/state.service';

@Component({
  selector: 'app-floating-submenu',
  templateUrl: './floating-submenu.component.html',
  styleUrls: ['./floating-submenu.component.scss']
})
export class FloatingSubmenuComponent implements OnInit, OnDestroy {
  
  @Input() services: any[];
  @Input() userRoutes: any[];
  @Input() roleRoutes: any[];
  @Input() searchPlaceholder: string;

  loadingModules: boolean;
  rootDinamic: string;
  activeSubMenu: boolean;
  itemSelectSubMenuList: string;

  private subscriptions: Subscription[] = [];

  constructor(
    public router: Router,
    private menuStateService: MenuStateService,
    private stateService: StateService,
    private messageAlerts: MessagesAlerts,
    private modulesManagementService: ModulesManagementService
  ) {
    this.loadingModules = false;
  }

  ngOnInit(): void {
    this.loadingModules = false;
    this.subscriptions.push(
      this.menuStateService.rootDinamic$.subscribe(rootDinamic => this.rootDinamic = rootDinamic),
      this.menuStateService.activeSubMenu$.subscribe(activeSubMenu => this.activeSubMenu = activeSubMenu),
      this.menuStateService.itemSelectSubMenuList$.subscribe(itemSelectSubMenuList => this.itemSelectSubMenuList = itemSelectSubMenuList)
    );
  }

  ngOnDestroy(): void {
    this.loadingModules = false;
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  redirect(route: string) {
    this.menuStateService.setActiveSubMenu(false);
    this.router.navigate([ route ]);
  }

  selectSubItem(root: string, index: number) {

    if (this.rootDinamic.toLowerCase() === 'services') {
      let itemServices = this.services[index];

      if (itemServices?.isChildrenBackend) this.loadDataModules(index);
    }

    this.menuStateService.setItemSelectSubMenuList(root + index);
  }

  gotoForm(root: string, items: any, subItem?: any) {
    let dataSend: any = {};

    if (items) dataSend = {state: {item: items}};
    if(subItem) dataSend = {state: {item: items, subItem: subItem}};

    this.onDocumentClick();
    this.stateService.setState(dataSend);
    return this.router.navigate([root], dataSend);
  }

  loadDataModules(index: any){
    this.loadingModules = true;
    this.modulesManagementService.getModulesGroup(ModuleBackend.MODULE_GROUPS_CLIENT, true)
    .subscribe((res: any) => {
      if(res.statusCode === 200){
        let subitems: any = [];
        
        res.data.map((el: any) => {
          subitems.push(
            { 
              ...el,
              name: el?.name , 
              subitems: this.getSubItemsChildren(el?.moduleGroupConfigs)
            }
          )
        })

        this.services[index].subitems = subitems;
      } else {
        this.services[index].subitems = [];
        this.messageAlerts.onError(res)
      }

      this.loadingModules = false;
    }, (error: any) => {
      this.services[index].subitems = [];
      this.loadingModules = false;
      this.messageAlerts.onError(error.error? error.error : "Ha ocurrido un error, intente nuevamente")
    })
  }

  getSubItemsChildren(subChildren: any) {
    let subtItemChildren: any = [];

    subChildren.map((el: any) => {
      subtItemChildren.push(
        { 
          ...el,
          name: el?.name,
          link: '/dashboard/form-expense-record' 
        }
      )
    })

    return subtItemChildren;
  }

  selectSubSubItem(root: string, index: number) {
    this.menuStateService.setItemSelectSubMenuList(root + '-' + index);
  }

  onDocumentClick(): void {
    this.menuStateService.setActiveSubMenu(false);
  }
  
  resetItemSelectSubMenuList() {
    this.menuStateService.setItemSelectSubMenuList('');
  }
}