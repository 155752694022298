import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss'],
})
export class SwiperComponent{
  @Input() listCompounds: any;
  @Input() loading: boolean = false;
  @Input() messageNoData!: string;
  @Input() size!: string;
  @Input() showNamePicture: boolean = true;
  @Input() alwaysEmitClick: boolean = false;
  @Input() imagePath: any = 'photosCompound[0]?.file';
  @Output() clickSlideEv = new EventEmitter<number>();
  compounSelected: any;
  compoundNoImage = 'assets/img/build-no-image.png';
  breakpointsSlides: any = {
    200: {
      slidesPerView: 2.2,
      spaceBetween: 10
    },
    350: {
      slidesPerView: 3.5,
      spaceBetween: 10
    },
    576: {
      slidesPerView: 4.5,
      spaceBetween: 10
    },
    768: {
      slidesPerView: 6.2,
      spaceBetween: 15
    },
    992: {
      slidesPerView: 5,
      spaceBetween: 20
    },
    1200: {
      slidesPerView: 6.3,
      spaceBetween: 20
    }
  }
  breakpointsSlidesVerySmall: any = {
    200: {
      slidesPerView: 5,
      spaceBetween: 10
    },
    350: {
      slidesPerView: 8,
      spaceBetween: 10
    },
    576: {
      slidesPerView: 10,
      spaceBetween: 10
    },
    768: {
      slidesPerView: 14,
      spaceBetween: 15
    },
    992: {
      slidesPerView: 18,
      spaceBetween: 20
    },
    1200: {
      slidesPerView: 20,
      spaceBetween: 20
    }
  }

  constructor() { }

  selectSlide(compund: any) {
    if (compund && compund.selected === false) {
      this.listCompounds.map((el: any) => {
        el.selected = false
        if (el.id === compund.id) el.selected = true
      });

      this.compounSelected = this.listCompounds.filter((el: any) => el?.selected === true)[0] ? this.listCompounds.filter((el: any) => el?.selected === true)[0] : null;

      this.clickSlideEv.emit(compund)
    }

    if (this.alwaysEmitClick === true) {
      this.clickSlideEv.emit(compund)
    }
  }

  classCompoundSelected(compound: any){
    return compound?.selected ? 'image-compound-selected-desktop' : 'image-compound-not-selected-desktop';
  }
}
