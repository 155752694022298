/* eslint-disable @typescript-eslint/naming-convention */

export const NavigationApp = {

  //#region GENERAL
  STATUS_APP: 'status-app',
  ROOT: '/',
  ERROR: 'not-found',
  //#endregion

  //#region LOGIN
  LOGIN: 'login',
  LOGIN_ACTIONS: 'actions',                // children of "LOGIN"
  LOGIN_FINGERPRINT: 'fingerprint',        // children of "LOGIN"
  RESET_PASSWORD: 'reset-password',        // children of "LOGIN"
  REGISTER_FROM_APP: 'register-from-app',  // children of "LOGIN"
  VALIDATE_ACCESS: 'validate-access',      // children of "LOGIN"
  FIRST_ACCESS: 'first-access',
  //#endregion

  //#region REGISTER
  REGISTER: 'register',
  //#endregion

  //#region ASSETS
  ASSETS_IMG: 'assets/img',
  //#endregion

  //#region DASHBOARD
  DASHBOARD: 'dashboard',
  USERS: 'users',
  ADMIN_PROFILES: 'admin-profiles',
  USERS_MENU: 'menu',
  HOME: 'home',
  SERVICES: 'services',
  NOTIFICATIONS: 'notifications',
  BILLING: 'billing',
  LIST_OPEN_NOTIFY: 'list-notifications-open',
  LIST_USER_NOTIFY: 'list-notifications-user',
  PROFILE: 'profile',                     // children of "DASHBOARD"
  PROFILE_USER: 'profile-user',           // children of "DASHBOARD"
  SETTINGS: 'settings',                   // children of "DASHBOARD"
  PAYMENT_METHODS: 'method',       // children of "PAYMENTS"
  CRUD_PAYMENT_METHOD: 'payment-methods',       // crud metodos de pago"
  SELECT_CLIENT: 'select_client',       // children of "HOME"
  PLAN_CLIENTS: 'plan-clients',
  //#endregion

  //#region SETTINGS
  SECURITY: 'security',     // children of "settings"    
  CLIENTS: 'clients',
  SELECT_PLAN: 'select-plan',
  CHANGE_PASSWORD: 'change-password',     // children of "settings"
  SET_FINGERPRINT: 'set-fingerprint',     // children of "settings"
  VERIFY_IDENTITY: 'verify-identity',     // children of "settings"
  MASTER_TABLES: 'master-tables',         // children of "settings"
  LEVEL: 'level',                         // children of "master-tables"
  TYPE_INVENTORY: 'type-inventory',       // children of "master-tables"
  UNIT_MEASURE: 'unit-of-measure',        // children of "master-tables"
  ECONOMIC_ACTIVITY: 'economic-activity', // children of "master-tables"
  PRICE_LIST: 'price-list',               // children of "master-tables"
  //#endregion

  //#region USERS
  ROLES: 'roles',           // children of "users"
  //#endregion

  //#region CRUD
  CREATE: 'create',
  UPDATE: 'update',
  LIST: 'list',
  //#endregion

  //#region ACCOUNTING
  FORM_EXPENSE_RECORD: 'form-expense-record',
  PLAN_ACCOUNTS: 'plan-accounts',
  MODULES_MANAGEMENT: 'modules-management',
  ASSISTANTS: 'assistants',

  //#region RECORD CONTROL
  RECORD_CONTROL: 'record-control',
  JOURNAL: 'journal',
  VOUCHER_LIST: 'voucher-list',
  LIST_DEFAULTERS: 'list-defaulters',
  //#endregion
  //#endregion

  //#region INVENTORY
  INVENTORY: 'inventory',
  PRODUCTS: 'products',
  //#region ORDERS
  FORM_ORDER_PURCHASE: 'form-order-purchase',
  FORM_ORDER_RECEIPTS: 'form-receipts-order-purchase',
  ORDERS: 'orders',
  ENTRY: 'entry',
  DEPARTURE: 'departure',
  SUMMARY: 'summary',
  //#endregion
  //#endregion

  //#region PURCHASE ORDERS
  PURCHASE_ORDERS: 'purchase-orders',
  ORDER_REQUEST: 'order-request',
  PURCHASE_ORDER: 'purchase-order',
  ORDER_LIST: 'order-list',
  ORDER_RECEIPTS: 'order-receipts',
  //#endregion

  //#region BILLING
  BILLING_V2: 'billing',
  INVOICE_REQUEST: 'invoice-request',
  QUOTED: 'quoted',
  // ORDER_RECEIPTS: 'order-receipts',
  //#endregion
};
