export const constants = {
  ASSETS_IMG: "assets/img",
  EMAIL: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\\.+(?:\.[a-zA-Z0-9-]+)*$",
  REGEX_EMAIL: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\\.+(?:\.[a-zA-Z0-9-]+)*$",
  REGEX_FIRSTNAME: "^[^0-9]*[a-zA-Z][^0-9]*$",
  REGEX_LASTNAME: "^[^0-9]*[a-zA-Z][^0-9]*$",
  REGEX_PASSWORD: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,12}$",
  REGEX_NUMBER: "^[0-9]+$",
  REGEX_HOUR: "^\d+([,.]\d+)?\s*hora(s)?$",
  FORMAT_DATE: "dd/MM/YYYY",
  MASK_PHONE_NUMBER: "0000 000-00-00",
  GENDER: [
    {
      label: "Femenino",
      value: "f"
    }, {
      label: "Masculino",
      value: "m"
    },
    {
      label: "Sin especificar",
      value: 's'
    }
  ],
  UNITS: [
    {
      label: "Unidades",
      value: "UNI"
    },
    {
      label: "Kilogramos",
      value: "KG"
    },
    {
      label: "Litros",
      value: "LTS"
    },
    {
      label: "Metros",
      value: "MTS"
    },
    {
      label: "Horas",
      value: "HRS"
    },
    {
      label: "Docenas",
      value: "DOC"
    }
  ],
  CATEGORY: [
    {
      label: "Mantenimiento",
      value: "MANT"
    },
    {
      label: "Seguridad",
      value: "SEG"
    },
    {
      label: "Limpieza",
      value: "LIM"
    },
    {
      label: "Administración",
      value: "ADMIN"
    },
    {
      label: "Jardinería",
      value: "JAR"
    },
    {
      label: "Servicios Generales",
      value: "SERVG"
    }
  ],
  ERRORS: [
    { type: 'required', message: "El campo no puede estar vacio." },
    { type: 'pattern', message: "Debe tener un formato correcto." },
  ],
  SUCESSFULL_SCREEN_TIMEOUT: 1000,
  TYPE_EXTENSION_IMAGE: ["image/png", "image/jpg", "image/jpeg"],
  REGEX_NUMBER_PHONE: "^([0-9]+).{9,12}$",
  MIME_TYPE_EXCEL: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  MIME_TYPE_PDF: "application/pdf",
  REGEX_FORMAT_DATE: /^(?:0[1-9]|[1-2][0-9]|3[0-1])-(?:0[1-9]|1[0-2])-(?:\d{4})$/, // DD-MM-YYYY
}
