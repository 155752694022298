export const StatusNotificationEnum = {
  ACTIVE: 'ACTIVO',
  INACTIVE: 'INACTIVO',
  EXPIRED: 'EXPIRADO',
  UPDATED: 'ACTUALIZADO',
};

export const StatusNotificationEnumColor = {
  ACTIVE: 'primary',
  INACTIVE: 'tertiary-four',
  EXPIRED: 'danger',
  UPDATED: 'blue',
};