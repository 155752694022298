<!-- <ion-header class="ion-no-border">
  <ion-item lines="none">
    <ion-button slot="end" fill="clear" shape="round" (click)="closeModal()">
      <ion-icon name="close" color="medium"></ion-icon>
    </ion-button>
  </ion-item>
</ion-header>

<ion-content>
  <div>
    <p class="ion-no-margin">
      Modal Notificaciones
    </p>
  </div>
</ion-content> -->

<ion-modal #modal [backdropDismiss]="false"
  (ionBreakpointDidChange)="changeBreakPoint($event)"
  [initialBreakpoint]="0.75"
  [isOpen]="visible" [breakpoints]="[0, 0.75]">
  <ng-template>
    <ion-header class="ion-no-border">
      <ion-item lines="none">
        <ion-button slot="end" fill="clear" shape="round" (click)="closeModal()">
          <ion-icon name="close" color="medium"></ion-icon>
        </ion-button>
      </ion-item>
    </ion-header>

    <ion-content class="ion-no-padding">
      <div class="content">
        <span class="divider"></span>

        <p class="ion-text-center margin-vertical-xs">
          <ion-text color="tertiary-two" class="title-date">Hoy</ion-text>
        </p>

        <p *ngIf="notifications.length <= 0" class="ion-text-center margin-top-md">
          <ion-text color="tertiary-two" class="title-date">No posee notificaciones</ion-text>
        </p>

        <div *ngIf="notifications && notifications.length > 0">
          <!-- <p class="margin-vertical-xs notification-first-line" >
            <ion-text class="title-notif" color="tertiary-three">{{notif.title}}</ion-text>
            <ion-text class="description-notif" color="tertiary-two">{{notif.body}}</ion-text>
          </p> -->
          <div class="notification-content" *ngFor="let notif of notifications; let i = index">
            <p class="margin-vertical-xs"
              [ngClass]="{'notification-first-line': clickNotif !== i}"
              (click)="showMore(i)">
              <ion-text class="title-notif" color="tertiary-three">{{notif.title}}</ion-text>

              <span class="description-notif" color="tertiary-two"
               *ngIf="notif && notif.body  && notif.body.includes('<')"
               [innerHTML]="notif.body"
               ></span>
              <span class="description-notif" color="tertiary-two"
               *ngIf="notif && notif.body  && !notif.body.includes('<')"
              >{{notif.body}}</span>

            </p>
          </div>
        </div>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>
