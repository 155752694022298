import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, first, Observable } from 'rxjs';
import { ModuleBackend, TypeOrderList } from '@enums/index';
import { ICrudResponse, IFilter, IManagement, IPagination, IResponse } from '@interfaces/index';
import { GenericCrudService } from '@common/classes/generic-crud.service';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class OrdersInventoryService extends GenericCrudService<IManagement> {
  readonly filterObj: IFilter[] = [
    {
      label: "Código del producto",
      controlName: "productCode",
      type: "text"
    },
    {
      label: "Costo del producto",
      controlName: "price",
      type: "amount"
    },
    {
      label: "Fecha de la operación",
      controlName: "date",
      type: "date"
    }
  ];
  private orderSelected$: BehaviorSubject<IManagement>;
  private showEdit$: BehaviorSubject<boolean>;
  private typeOrderList$: BehaviorSubject<TypeOrderList>;
  private ordersInventoryManagementURL: ModuleBackend;
  private ordersInventoryManagementProductURL: ModuleBackend;

  constructor() {
    const ordersInventoryURL = (ModuleBackend.INVENTORY_MANAGEMENT + '/' + ModuleBackend.OPERATIONS) as ModuleBackend;
    super(ordersInventoryURL);
    this.ordersInventoryManagementURL = (ModuleBackend.INVENTORY_MANAGEMENT + '/' + ModuleBackend.TRANSACTION) as ModuleBackend;
    this.ordersInventoryManagementProductURL = (ModuleBackend.INVENTORY_MANAGEMENT + '/' + ModuleBackend.PRODUCT) as ModuleBackend;
    this.orderSelected$ = new BehaviorSubject(null);
    this.showEdit$ = new BehaviorSubject(false);
    this.typeOrderList$ = new BehaviorSubject(TypeOrderList.NONE);
  }

  override create(data: Partial<IManagement>): Observable<IResponse<IManagement>> {
    return this.http.post<IResponse<IManagement>>(`
      ${environment.API_URL}/${this.ordersInventoryManagementURL}`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }
  readOnes(id: number): Observable<IResponse<IManagement[]>> {
    return this.http.get<IResponse<IManagement[]>>(`
      ${environment.API_URL}/${this.ordersInventoryManagementProductURL}/${id}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }
  override readAll(pagination: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {} | {}[]): Observable<ICrudResponse<IManagement>> { return super.readAll(pagination, extraKeys); }

  setOrder(order: IManagement) { this.orderSelected$.next(order); }
  getOrder() { return this.orderSelected$.getValue(); }
  setshowEdit(show: boolean = false) { this.showEdit$.next(show); }
  getshowEdit() { return this.showEdit$.getValue(); }
  setTypeOrderList(type: TypeOrderList) { this.typeOrderList$.next(type); }
  getTypeOrderList() { return this.typeOrderList$.getValue(); }
}
