<div class="content-modal">
  <div class="question-content">
    <ion-text color="secondary-three">{{title}}</ion-text>
  </div>
  <div class="icon-content" *ngIf="!file">
    <ion-icon name="help-circle-outline" color="tertiary-two" class="animate__animated animate__zoomIn"></ion-icon>
  </div>
  <div class="icon-content" *ngIf="file">
    <ion-icon name="checkmark-done-circle-outline" color="tertiary-two" class="animate__animated animate__zoomIn"></ion-icon>
  </div>
  <div class="detail-content" *ngIf="!file">
    <ion-text color="danger" class="ion-text-center">
      {{detailElement}}
    </ion-text>
  </div>

  <ion-grid>
    <ion-row>
      <ion-col size="12">
        
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" size-md="6" size-lg="4" size-xl="3" class="content-field">
        <ion-item lines="none" class="form-field">
          <ion-input 
            class="ion-no-margin" 
            formControlName="file" 
            type="file"
            (ionChange)="changeFile($event)"
          >
          </ion-input>
        </ion-item>
    
      </ion-col>
    </ion-row>
  </ion-grid>

  <div class="content-buttons" >
    <ion-grid class="ion-no-padding">
      <ion-row class="ion-no-padding">
        <ion-col size="6">
          <ion-button fill="outline" expand="block" (click)="cancel()"
            class="button-round ion-no-margin margin-right-xs margin-bottom-xs">
            Cancelar
          </ion-button>
        </ion-col>
        <ion-col size="6">
          <ion-button fill="outline" expand="block" (click)="btnAction()" 
            class="button-round ion-no-margin margin-left-xs margin-bottom-xs"
            [disabled]="!file">
            {{action}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>