import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CheckDevelopGuard implements CanActivate {

  constructor(
    public router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {

    if (environment?.production == false) return true;
    return false;
  }
}
