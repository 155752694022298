export enum PaymentStatus {
    pending,
    approved,
    rejected,
}

export enum BillStatus {
    pending,
    paid,
    expired
  }