import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { IGoogleMapInfo } from '@interfaces/index';
import { ModalController } from '@ionic/angular';
import { UtilsFunctions } from '@common/functions/utils';
import { Geolocation } from '@capacitor/geolocation';
import { GoogleMap } from '@capacitor/google-maps';
import { Observable, Subscription, catchError, finalize, first, from } from 'rxjs';
import { GeolocationErrors } from '@enums/index';
import { MapGeocoder, MapGeocoderResponse } from '@angular/google-maps';
import { MapClickCallbackData } from '@capacitor/google-maps/dist/typings/definitions';
import { environment } from '@environments/environment';

@Component({
  selector: 'google-map-modal',
  templateUrl: './google-map-modal.page.html',
  styleUrls: ['./google-map-modal.page.scss']
})
export class GoogleMapModal {
  @Input() geographicalReference!: IGoogleMapInfo;
  @Input() read: boolean = false;
  @ViewChild('map') mapRef!: ElementRef<HTMLElement>;
  newMap!: GoogleMap;
  private geolocationCoordinates: Partial<GeolocationCoordinates>;
  loadingText: string;
  private markers: string[];
  googleMapInfo: IGoogleMapInfo;
  private currentPositionSubscription!: Subscription;
  private mapSubscription!: Subscription;
  loadingAddress: boolean;

  constructor(
    private modalCtrl: ModalController,
    private geoCoder: MapGeocoder,
    private utils: UtilsFunctions
  ) {
    this.loadingText = "Cargando...";
    this.geolocationCoordinates = {
      // California
      latitude: 10.479931,
      longitude: -66.8201208
      // Guatire, Buena Vista
      // latitude: 10.47565,
      // longitude: -66.563457
    };
    this.markers = [];
    this.googleMapInfo = {
      address: "",
      latitude: 0,
      longitude: 0
    }
    this.loadingAddress = false;
  }

  ionViewWillEnter() {
    console.log("geographicalReference", this.geographicalReference);
    this.loadingAddress = true;
    if (this.geographicalReference && this.geographicalReference.latitude && this.geographicalReference.longitude) {
      this.geolocationCoordinates = {
        latitude: this.geographicalReference.latitude,
        longitude: this.geographicalReference.longitude
      };
      this.createMap(this.geolocationCoordinates, true);
    } else
      this.currentPositionSubscription = from(Geolocation.getCurrentPosition({ enableHighAccuracy: true }))
        .subscribe({
          next: pos => {
            this.geolocationCoordinates = pos.coords;
            this.createMap(this.geolocationCoordinates);
          },
          error: err => {
            if (err.code === GeolocationErrors.PERMISSION_DENIED)
              this.loadingText = "No se pudo obtener la localización por permiso denegado, se usará una ubicación por defecto.";
            else if (err.code === GeolocationErrors.POSITION_UNAVAILABLE)
              this.loadingText = "No se pudo obtener la localización por posicionamiento inválido, se usará una ubicación por defecto.";
            else if (err.code === GeolocationErrors.TIMEOUT)
              this.loadingText = "No se pudo obtener la localización, se usará una ubicación por defecto.";
            else this.loadingText = err.message;

            this.utils.presentToast(this.loadingText, "warning");
            setTimeout(() => this.createMap(this.geolocationCoordinates), 1000);
          }
        });
  }

  createMap(geolocationCoordinates: Partial<GeolocationCoordinates>, zoomed: boolean = false) {
    this.mapSubscription = from(GoogleMap.create({
      id: 'my-cool-map',
      element: this.mapRef.nativeElement,
      apiKey: environment.GOOGLE_MAP_API_KEY,
      config: {
        center: {
          lat: geolocationCoordinates.latitude!,
          lng: geolocationCoordinates.longitude!,
        },
        zoom: zoomed ? 15 : 10,
      },
      forceCreate: true
    })).subscribe({
      next: map => {
        this.baseReverseGeocoding(geolocationCoordinates).subscribe(res => {
          console.log('baseReverseGeocoding', res);
          if (res.status !== google.maps.GeocoderStatus.OK)
            this.utils.presentToast("No se pudo obtener la dirección, se usarán las coordenadas", "warning");
          this.googleMapInfo = {
            latitude: geolocationCoordinates.latitude!,
            longitude: geolocationCoordinates.longitude!,
            address: res.results.length > 0 ? "Hay resultado, falta probar" : `Latitud: ${geolocationCoordinates.latitude}, Longitud: ${geolocationCoordinates.longitude}`
          };
          this.initializeMap(map, geolocationCoordinates);
        });
      },
      error: err => {
        // console.log('createMap error', err);
        this.loadingAddress = false;
      }
    });
  }

  private initializeMap(map: GoogleMap, coordinates: Partial<GeolocationCoordinates> | MapClickCallbackData) {
    this.newMap = map;
    this.newMap.enableTouch();
    this.newMap.disableClustering();
    // this.newMap.enableCurrentLocation(true);
    this.setMarkers(this.newMap, coordinates);
    if (!this.read) {
      this.newMap.setOnMapClickListener(x => {
        this.setMarkers(this.newMap, x);
        this.centerMap(this.newMap, x);
        this.reverseGeocoding(x);
      });
      this.newMap.setOnMarkerDragEndListener(x => {
        this.setMarkers(this.newMap, x);
        this.centerMap(this.newMap, x);
        this.reverseGeocoding(x);
      });
    } else {
      this.newMap.disableTouch();
      this.newMap.setCamera({ zoom: 20 });
    }
    console.log("GOOGLE MAPS", this.newMap);
  }

  private setMarkers(map: GoogleMap, coordinates: Partial<GeolocationCoordinates> | MapClickCallbackData) {
    if (this.markers.length === 0)
      map.addMarker({
        coordinate: {
          lat: coordinates.latitude!,
          lng: coordinates.longitude!
        },
        draggable: true
      }).then(mark => this.markers.push(mark));
    else
      map.removeMarkers(this.markers).then(() => {
        this.markers.length = 0;
        map.addMarker({
          coordinate: {
            lat: coordinates.latitude!,
            lng: coordinates.longitude!
          },
          draggable: true
        }).then(mark => this.markers.push(mark));
      });
  }

  private centerMap(map: GoogleMap, coordinates: Partial<GeolocationCoordinates> | MapClickCallbackData) {
    map.setCamera({
      coordinate: {
        lat: coordinates.latitude!,
        lng: coordinates.longitude!
      },
      animate: true
    });
  }

  private baseReverseGeocoding(coordinates: Partial<GeolocationCoordinates> | MapClickCallbackData): Observable<MapGeocoderResponse> {
    return this.geoCoder.geocode({ location: { lat: coordinates.latitude!, lng: coordinates.longitude! } })
      .pipe(
        first(),
        finalize(() => this.loadingAddress = false),
        catchError(error => {
          console.log('baseReverseGeocoding Error', error);
          throw error;
        })
      );
  }

  private reverseGeocoding(coordinates: Partial<GeolocationCoordinates> | MapClickCallbackData) {
    this.loadingAddress = true;
    this.baseReverseGeocoding(coordinates).subscribe(res => {
      console.log('reverseGeocoding', res);
      if (res.status !== google.maps.GeocoderStatus.OK)
        this.utils.presentToast("No se pudo obtener la dirección, se usarán las coordenadas", "warning");
      this.googleMapInfo = {
        latitude: coordinates.latitude!,
        longitude: coordinates.longitude!,
        address: res.results.length > 0 ? "Hay resultado, falta probar" : `Latitud: ${coordinates.latitude}, Longitud: ${coordinates.longitude}`
      };
    });
  }

  cancel() { this.modalCtrl.dismiss(null, "cancel"); }
  confirm() { this.modalCtrl.dismiss(this.googleMapInfo, "confirm"); }
  ionViewWillLeave() {
    this.currentPositionSubscription?.unsubscribe();
    this.mapSubscription?.unsubscribe();
    this.newMap.destroy();
  }
}
