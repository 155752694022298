import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { LoadingService } from '@services/loading.service';
import { Observable, finalize, of, catchError, tap } from 'rxjs';
import { NavigationApp } from '@navigation/*';
import { AuthType } from '@enums/authTypeUser.enum';
import { AuthService, HomeService, LocalStorageService } from 'src/services';
import { ModuleBackend } from '@enums/modules-backend.enum';
import { IErrorResponse } from '@interfaces/*';
import { MessagesAlerts } from '@common/index';


@Injectable({
  providedIn: 'root'
})
export class LoadPermissionsResolver implements Resolve<Observable<any>> {

  listClients: any[] = [];

  constructor(
    public router: Router,
    private authService: AuthService,
    private homeService: HomeService,
    private loadingService: LoadingService,
    private _localStorage: LocalStorageService,
    private messagesAlerts: MessagesAlerts
  ) { }

  resolve(): Observable<any> {
    this.loadingService.showLoading();

    const userSession = this._localStorage.getItem('userSession');

    if (userSession && userSession.data && userSession.data.token) {
        this.listClients = userSession?.data?.client;

        if (!userSession.data.infoClientSelected && this.listClients?.length) {
          return this.homeService.selectProperty(ModuleBackend.AUTH_SAE, this.listClients[0].clientId).pipe(
            tap((res: any) => {
              if (res.statusCode === 200) {
                // if (!this._localStorage.getItem('userSession')?.data?.selectedClient && this.listClients && this.listClients.length > 1) this.router.navigate(['/dashboard/select_client']);
                // else 
                this.router.navigate(['/dashboard/home']);
                this.loadingService.hideLoading();
              } else {
                this.onError(res);
                this.router.navigate(['/dashboard/home']);

                // this.router.navigate(['/dashboard/select_client']);
              }
            }),
            catchError((error: any) => {
              this.onError(error.error);

              this.router.navigate(['/dashboard/home']);

              // this.router.navigate(['/dashboard/select_client']);
              return of(null);
            }),
            finalize(() => this.loadingService.hideLoading())
          );
        } else {
          return this.authService.isAuthenticated().pipe(
            finalize(() => this.loadingService.hideLoading())
          );
        }
    } else {
      this.loadingService.hideLoading();
      this.router.navigate([NavigationApp.LOGIN]);
      return of(null);
    }
  }

  onError(error: IErrorResponse) { this.messagesAlerts.openToast(error.message, "tertiary-four", 1500, 'bottom'); }

}
