import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { environment } from '@environments/environment';
import { first, map } from 'rxjs';
import { LocalStorageService } from './common';
import { NavigationApp } from '@navigation/*';


@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private API_URI: string;
  

  constructor(
    private http: HttpClient,
    private _localStorage: LocalStorageService,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  getDataHome(Module: ModuleBackend) {
    return this.http.get(
      `${this.API_URI}/${Module}?page=1&skip=10000000`,
      this.headerComplement.complementHeader()
    );
  }

  getDataHomeProperty(Module: ModuleBackend) {
    return this.http.get(
      //Module = receipt
      `${this.API_URI}/${Module}/dashboard`,
      this.headerComplement.complementHeader()
    );
  }

  getNumbersByCompound(Module: ModuleBackend, id: any) {
    return this.http.get(
      `${this.API_URI}/${Module}/compound-count/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  getAllNumbersByCompound(Module: ModuleBackend, id: any) {
    return this.http.get(
      `${this.API_URI}/${Module}/summary/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  selectProperty(Module: ModuleBackend, clientId: any, statusSelectedClient: boolean = false) {
    return this.http.post(
      `${this.API_URI}/${Module}/${NavigationApp.SELECT_CLIENT}/${clientId}`,
      {},
      this.headerComplement.complementHeader()
    ).pipe(
      map((userSession: any) => {
        if (userSession.statusCode == 200) {
          console.log('dsf',userSession);
          let tempSessionUser = this._localStorage.getItem('userSession');
          tempSessionUser.data.menu = userSession.data.menu;
          tempSessionUser.data.clientId = clientId;
          const {preferredCurrencyRef, localCurrency} = tempSessionUser?.data?.client.find((x:any) => x.clientId === clientId)?.client;  
          tempSessionUser.data.selectedClient = statusSelectedClient,    
          tempSessionUser.data.infoClientSelected = {
            role: userSession?.data?.role,
            client: userSession?.data?.client,
            clientCurrencies: {preferredCurrencyRef, localCurrency}
          }
          localStorage.setItem('userSession', JSON.stringify(tempSessionUser));
        }
        return userSession as any;
      })
    );
  }

  checkBillStatus() {
    return this.http.get(
      `${this.API_URI}/${ModuleBackend.AUTH_SAE}/check_bill_status`,
      this.headerComplement.complementHeader()
    );
  }


  selectClient(Module: ModuleBackend, clientId: any) {
    return this.http.post(
      `${this.API_URI}/${Module}/clients/users`,
      { clientId : clientId },
      this.headerComplement.complementHeader(),
    ).pipe(
      map((userSession: any) => {
        if (userSession.statusCode == 200) {
          let tempSessionUser = this._localStorage.getItem('userSession');
          tempSessionUser.data.menu = userSession.data.menu;
          tempSessionUser.data.clientId = clientId;
         
          localStorage.setItem('userSession', JSON.stringify(tempSessionUser));
        }
        return userSession as any;
      })
    );
  }

  reportsClient(Module: ModuleBackend = ModuleBackend.REPORTS_CLIENT_DASHBOARD){
    return this.http.post(
      `${this.API_URI}/${Module}`,
      {},
      this.headerComplement.complementHeader(),
    ).pipe(first())
  }
}
