<div class="padding-vertical-xs content-swiper">
  <swiper-container slides-per-view="3.5" [breakpoints]="size && size === 'very-small'? breakpointsSlidesVerySmall : breakpointsSlides" speed="500" loop="false" css-mode="true"
    space-between="10" centered-slides="false" *ngIf="!loading" class="swiper-container">
  <swiper-slide [class]="'slide ion-text-center' + ' ' + classCompoundSelected(compound)" *ngFor="let compound of listCompounds"
    (click)="selectSlide(compound)" slidesOffsetAfter="0">
    <div [class]="'image-compound' + ' ' + classCompoundSelected(compound)" [ngClass]="{'size-small': size && size === 'small', 'size-very-small': size && size === 'very-small'}">
      <ion-img *ngIf="compound.file && compound.file.startsWith('https')" [ngClass]="{'image-no-select': !compound.selected}"
        [src]="compound.file"
        [alt]="compound.name"
      ></ion-img>

      <ion-img *ngIf="compound.photosCompound || compound.file.startsWith('data:image/')" 
        [ngClass]="{'image-no-select': !compound.selected}"
        [src]="compound.photosCompound && compound.photosCompound[0]?.file && compound.photosCompound[0]?.file.startsWith('data:image/')? compound.photosCompound[0].file :
          compound.file && compound.file.startsWith('data:image/')? compound.file : compoundNoImage"
        [alt]="compound.name"
      ></ion-img>

      <div class="overlay-image" *ngIf="!compound.selected"></div>
    </div>
    <ion-text color="secondary-three" [ngClass]="{'opacity-text': !compound.selected}" *ngIf="showNamePicture"
      class="name-compound ion-text-center">
      {{compound.name}}
    </ion-text>
  </swiper-slide>
</swiper-container>

<swiper-container slides-per-view="3.5" speed="500" loop="false" css-mode="true" allowTouchMove="false" noSwiping
  space-between="10" centered-slides="false" *ngIf="loading" [breakpoints]="breakpointsSlides" enabled="false">
  <swiper-slide *ngFor="let item of [].constructor(10)" class="ion-text-center" class="slide">
    <div class="image-compound" [ngClass]="{'size-small': size && size === 'small', 'size-very-small': size && size === 'very-small'}" style="background: transparent;">
      <ion-thumbnail *ngIf="loading" style="width: 100%; height: 100%; --border-radius: 15px;">
        <ion-skeleton-text [animated]="true"></ion-skeleton-text>
      </ion-thumbnail>
    </div>
    <ion-skeleton-text [animated]="true" style="width: 80%; margin-left: auto; margin-right: auto;" class="margin-vertical-xs"></ion-skeleton-text>
  </swiper-slide>
</swiper-container>

<div *ngIf="!loading && (!listCompounds || listCompounds.length <= 0)" class="ion-text-center">
  <ion-text color="tertiary-two" class="font-size-xs ">{{messageNoData? messageNoData : 'No hay Recintos definidos'}}</ion-text>
</div>
</div>
