import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appUppercase]'
})
export class UppercaseDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const input = this.el.nativeElement as HTMLInputElement | HTMLTextAreaElement;

    if (this.isInputOrTextarea(input)) {
      const start = input.selectionStart;
      const end = input.selectionEnd;

      input.value = input.value.toUpperCase();
      
      if (start !== null && end !== null) {
        input.setSelectionRange(start, end);
      }
    }
  }

  private isInputOrTextarea(element: HTMLElement): element is HTMLInputElement | HTMLTextAreaElement {
    return element.tagName === 'INPUT' || element.tagName === 'TEXTAREA';
  }
}
