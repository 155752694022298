import { HttpComplement } from 'src/common/functions/http-complement';
import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUser, IUsers, IPagination } from 'src/interfaces';
import { Observable } from 'rxjs';
import { UrlTree } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private API_URI: string;

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  getUser(module: ModuleBackend, id: number): Observable<boolean | UrlTree> | any {
    return this.http.get(`
      ${this.API_URI}/${module}/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  getUserProperty(module: ModuleBackend, id: number): Observable<boolean | UrlTree> | any {
    return this.http.get(`
      ${this.API_URI}/${module}/user/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  getUsers(module: ModuleBackend, pagination: IPagination, status: number = 1): Observable<boolean | UrlTree> | any {
    return this.http.get(`
      ${this.API_URI}/${module}?page=${pagination.currentPage}&skip=${pagination.skip}&isActive=${status == 1 ? true: false }`,
      this.headerComplement.complementHeader()
    );
  }

  getAllUsers(Module: ModuleBackend, pagination: any, filter?: any){
    return this.http.get(
      `${this.API_URI}/${Module}/user/compound/all?page=${pagination.page}&skip=${pagination.skip}&compoundId=${filter.compundID}${filter.name? '&name='+filter.name : ''}`,
      this.headerComplement.complementHeader()
    );
  }

  updateUser(module: ModuleBackend, id: number, user: IUser): Observable<boolean | UrlTree> | any {
    return this.http.patch(`
      ${this.API_URI}/${module}/${id}`,
      user,
      this.headerComplement.complementHeader()
    );
  }

  deleteUserMd(Module: ModuleBackend, id: any){
    return this.http.delete(
      `${this.API_URI}/${Module}/user/delete/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  UpdateUserPropertyClient(module: ModuleBackend, data: any, id: any){
    return this.http.patch(`
      ${this.API_URI}/${module}/client/user/update/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  updateUserMd(Module: ModuleBackend, data: any, id: any){
    return this.http.patch(
      `${this.API_URI}/${Module}/user/update/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  updateUserProperty(module: ModuleBackend, id: number, user: IUser): Observable<boolean | UrlTree> | any {
    return this.http.patch(`
      ${this.API_URI}/${module}/user/update/${id}`,
      user,
      this.headerComplement.complementHeader()
    );
  }

  deleteUserProperty(module: ModuleBackend, id: number): Observable<boolean | UrlTree> | any {
    return this.http.delete(`
      ${this.API_URI}/${module}/user/delete/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  recoverPassword(module: ModuleBackend, data: any): Observable<boolean | UrlTree> | any {
    return this.http.post(`
      ${this.API_URI}/${module}/recover_password`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  verifyOtpCode(module: ModuleBackend, data: any): Observable<boolean | UrlTree> | any {
    return this.http.post(`
      ${this.API_URI}/${module}/verify_otp_code`,
      data,
      this.headerComplement.complementHeader()
    );
  }


  changeRecoverPassword(module: ModuleBackend, data: any): Observable<boolean | UrlTree> | any {
    return this.http.post(`
      ${this.API_URI}/${module}/change_recover_password`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  resetPassword(module: ModuleBackend, data: any): Observable<boolean | UrlTree> | any {
    return this.http.post(`
      ${this.API_URI}/${module}/reset_password`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  deleteUser(module: ModuleBackend, id: number): Observable<boolean | UrlTree> | any {
    return this.http.patch(`
      ${this.API_URI}/${module}/update_status/${id}`,
      {},
      this.headerComplement.complementHeader()
    );
  }
}
