import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent  implements OnInit {

  @Input() questionConfirm:  string = '';
  @Input() detailElement: string = '';
  @Input() action: string = '';
  @Input() showFieldComment: boolean = false;
  @Input() requiredComment: boolean = false;
  comment: string = "";

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  cancel(){
    this.modalCtrl.dismiss(false)
  }

  btnAction(){    
    if(this.showFieldComment){
      this.modalCtrl.dismiss({role: true, comment: this.comment})
    } else this.modalCtrl.dismiss(true)
  }

  changeComment(event: any){
    this.comment = event.detail.value;
  }

}
