<ion-content>
  <section class="container">
    <section class="content-header">
      <ion-toolbar color="primary">
        <ion-title>Ubicación por Google Maps</ion-title>
      </ion-toolbar>
    </section>
    <section class="content">
      <div class="map-content" [ngStyle]="{visibility: newMap ? 'visible':'hidden'}">
        <capacitor-google-map #map></capacitor-google-map>
        <ion-item lines="full" [disabled]="loadingAddress">
          <ion-input label="Ubicación seleccionada" labelPlacement="floating" [value]="googleMapInfo.address" readonly>
          </ion-input>
          <ion-spinner *ngIf="loadingAddress" name="lines-small" slot="end"></ion-spinner>
        </ion-item>
      </div>
      <ng-container *ngTemplateOutlet="loading"></ng-container>
    </section>
    <section class="content-footer ion-justify-content-around ion-margin-bottom">
      <ion-button (click)="cancel()" fill="solid" shape="round">Cancelar</ion-button>
      <ion-button (click)="confirm()" fill="solid" shape="round"
        [disabled]="!newMap || loadingAddress">Confirmar</ion-button>
    </section>
  </section>
</ion-content>

<ng-template #loading>
  <section class="ion-modal-loading" *ngIf="!newMap">
    <ion-row class="ion-justify-content-center">
      <ion-spinner name="dots"></ion-spinner>
    </ion-row>
    <ion-row class="ion-text-center">
      <ion-label>
        <small><strong>{{loadingText}}</strong></small>
      </ion-label>
    </ion-row>
  </section>
</ng-template>