export enum TypeAuxiliaryName {
    BANKS = "Bancos",
    OWNERS = "Propietarios",
    PROVIDERS = "Proveedores",
    WORKERS = "Trabajadores",
    PRODUCTS = "Productos",
    SUPPLIES = "Insumos",
    OTHERS = "Otros",
    CLIENTS = "Clientes",
    BOX = "Caja",
}