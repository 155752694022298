import { ModuleBackend } from '@enums/index';
import { Injectable } from '@angular/core';
import { Observable, catchError, first } from 'rxjs';
import { GenericCrudService } from '@common/classes/generic-crud.service';
import { ICrudResponse, IPagination, IClientsUsers, IResponse } from '@interfaces/index';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ClientsUsersService extends GenericCrudService<IClientsUsers> {

  constructor() { super(ModuleBackend.USER_SAE_CLIENT); }

  override create(data: Partial<IClientsUsers>): Observable<IResponse<IClientsUsers>> { return super.create(data); }
  override readOne(id: number): Observable<IResponse<IClientsUsers>> { return super.readOne(id); }
  override readAll(pagination: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {} | {}[]): Observable<ICrudResponse<IClientsUsers>> { return super.readAll(pagination, extraKeys); }
  override update(id: number, data: Partial<IClientsUsers>): Observable<IResponse<IClientsUsers>> { return super.update(id, data); }
  override delete(id: number): Observable<IResponse<IClientsUsers>> { return super.delete(id); }
  deactivate(id: number): Observable<IResponse<IClientsUsers>> {
    return this.http.patch<IResponse<IClientsUsers>>(`
      ${environment.API_URL}/${ModuleBackend.USER_SAE_CLIENT}/${id}`,
      null,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }
}
