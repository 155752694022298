import { Router } from '@angular/router';
import { ModalController, Platform, NavController } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { MessagesService } from '@services/common';
import { SplashScreen } from '@capacitor/splash-screen';
import { Component, NgZone, OnInit } from '@angular/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { messages } from '@common/index';
import { LottieSplashScreen } from '@awesome-cordova-plugins/lottie-splash-screen';
import { Capacitor } from '@capacitor/core';
import { LoadingService } from '@services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  showSplash!: boolean;

  constructor(
    private router: Router,
    private zone: NgZone,
    private navCtrl: NavController,
    private _platform: Platform,
    private modalController: ModalController,
    private _messagesService: MessagesService,

  ) {
    this.showSplash = true;
    // this._platform.backButton.subscribeWithPriority(10, () => {
    //   if (this.modalController.getTop()) {
    //     // Si hay un modal abierto, evita el gesto de retroceso en dispositivos móviles
    //     return;
    //   }
    // });

    // this._platform.backButton.subscribeWithPriority(0, (): boolean | any => {
    //   if (this.modalController.getTop()) {
    //     // Si hay un modal abierto, evita el gesto de retroceso en la web
    //     return false;
    //   }
    // });

    this.initializeApp();
  }

  ngOnInit() {
    this.logDeviceInfo();
    this.logBatteryInfo();
  }

  ionViewWillEnter() {
    
    // ScreenOrientation.lock('portrait');

    // this._platform.backButton.subscribeWithPriority(10, () => {
    //   console.log('ddd');
    //   if (this.modalController.getTop()) {
    //     console.log('aaa');
    //     // Si hay un modal abierto, evita el gesto de retroceso en dispositivos móviles
    //     // return;
    //   }
    // // });

    // // this._platform.backButton.subscribeWithPriority(0, (): boolean | any => {
    //   if (this.modalController.getTop()) {
    //     console.log('eeee');
    //     // Si hay un modal abierto, evita el gesto de retroceso en la web
    //     // return false;
    //   }
    // });
  }

  ////////////////////////// METHOD ///////////////////////////////////////

  private initializeApp(): void {
    this._platform.ready().then((res: any) => {
      SplashScreen.hide();
      this.OpenUrlNav();
      this.Splash(3000)
    })
      .catch(() => SplashScreen.hide());
  }

  async OpenUrlNav() {
    await App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://cintuz.gsoxware.io/register/:uniqueCode/:propertyId
        // slug = /register/:uniqueCode/:propertyId
        const slug = event.url.split(".com/").pop();
        if (slug) {
          // this.router.navigateByUrl(slug);
          this.router.navigate([slug]);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });

    // await App.addListener('backButton', (data) => {
    //   if (data.canGoBack) {
    //     // La aplicación puede retroceder, por lo tanto se puede ejecutar una acción específica
    //     console.log('La aplicación puede retroceder');
    //     // Agregar aquí el código que deseas ejecutar cuando la aplicación puede retroceder
    //   } else {
    //     // La aplicación no puede retroceder
    //     console.log('La aplicación no puede retroceder');
    //     // Agregar aquí el código que deseas ejecutar cuando la aplicación no puede retroceder
    //   }
    // });
  }

  async Splash(time: number) {
    await LottieSplashScreen.show();
    // await SplashScreen.show({
    //   showDuration: time,
    //   autoHide: true
    // });
  }

  private logDeviceInfo = async () => {
    const info: any = await Device.getInfo();
    this.getPlatforms(info);
  };

  private getPlatforms(data: any) {

    /// VALIDACION PARA BLOQUEAR - EMULADORES.

    // if (
    //     data &&
    //     !data.isVirtual &&
    //     (
    //       data.platform === 'android' ||
    //       data.platform === 'ios' ||
    //       data.platform === 'iphone' ||
    //       data.platform === 'ipad' ||
    //       data.platform === 'tablet'
    //     )
    //   ) null
    // else {
    //   localStorage.clear();
    //   App.exitApp();
    // }
  }

  // private screenOrientationInfo(data: any) {
  //   // if (
  //   //     data &&
  //   //     !data.isVirtual &&
  //   //     (
  //   //       data.platform === 'android' ||
  //   //       data.platform === 'ios' ||
  //   //       data.platform === 'iphone' ||
  //   //       data.platform === 'ipad' ||
  //   //       data.platform === 'tablet'
  //   //     )
  //   //   ) {
  //   //   }
  //     ScreenOrientation.lock({ orientation: "portrait" });
  //     ScreenOrientation.lock("portrait");

  //     ScreenOrientation.unlock();
  // }

  private logBatteryInfo = async () => {
    const info: any = await Device.getBatteryInfo();
    if (info && info.batteryLevel < 0.15) this._messagesService.showToast(messages.BATERY_LEVEL, 3000, 350, 'middle', 'toastBatery');
  };

  //////////////////////////////////////////////////////////////
}
