<div class="content-modal-notify" [ngClass]="{ classContent: classContent }">
  <ion-label color="secondary-three">
    {{ title }}
  </ion-label>

  <ion-icon id="icon" [src]="icon"></ion-icon>

  <ion-text class="color-subt" [ngClass]="{ 'pb-15': (!actionButton && subtitle && !subtitle1 && !subtitle2 && !formArrayLabel)  }" *ngIf="subtitle">{{ subtitle }}</ion-text>

  <ion-text *ngFor="let item of formArrayLabel; let i = index;" [ngClass]="{ 'pb-15': (i > formArrayLabel.length) }">
    <ion-checkbox labelPlacement="end" *ngIf="isActionCheckMultiple" #checkItem (ionChange)="getCheck(checkItem, item, i)">{{ item.subtitle }}</ion-checkbox>
    <span *ngIf="!isActionCheckMultiple">{{ item.subtitle }}</span>
  </ion-text>

  <ion-text [ngClass]="{ 'pb-15': (!actionButton && subtitle && subtitle1 && !subtitle2 ) }" *ngIf="subtitle1 && !formArrayLabel">{{ subtitle1 }}</ion-text>
  <ion-text [ngClass]="{ 'pb-15': (!actionButton && subtitle && subtitle1 && subtitle2) }" *ngIf="subtitle2 && !formArrayLabel">{{ subtitle2 }}</ion-text>
  <ion-text [ngClass]="{ 'pb-15': (!actionButton && subtitle && subtitle2 && subtitle3) }" *ngIf="subtitle3 && !formArrayLabel">{{ subtitle3 }}</ion-text>
  <ion-text [ngClass]="{ 'pb-15': (!actionButton && subtitle && subtitle3 && subtitle4) }" *ngIf="subtitle4 && !formArrayLabel">{{ subtitle4 }}</ion-text>
  <ion-text [ngClass]="{ 'pb-15': (!actionButton && subtitle && subtitle4 && subtitle5) }" *ngIf="subtitle5 && !formArrayLabel">{{ subtitle5 }}</ion-text>

  <ion-row [ngClass]="{ classRowAction: classRowAction }" *ngIf="actionButton">
    <ion-col>
      <ion-button [ngClass]="{ classAction0: classAction0 }" fill="outline" (click)="Close()" mode="md" expand="block">
        {{ textBtn && textBtn.length > 0 ? textBtn[0].title: 'ejemplo' }}
      </ion-button>
      <ion-button [ngClass]="{ classAction1: classAction1 }" [disabled]="isActionCheckMultiple ? !checkSelected(): false" (click)="EmitAction('action')" fill="outline" mode="md" expand="block">
        {{ textBtn && textBtn.length > 1 ? textBtn[1].title: 'ejemplo' }}
      </ion-button>
    </ion-col>
  </ion-row>
</div>
