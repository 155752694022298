import { Injectable } from "@angular/core";
import { Location } from '@angular/common';
import { LoadingController, ModalController, PopoverController, ToastController } from "@ionic/angular";
import { ValidationErrors } from "@angular/forms";
import { Formats } from "@enums/formats.enum";
import { LocalStorageService } from "@services/common";
import { TypePropertyUser } from "@enums/type-user.enum";
import { Observable } from "rxjs";
import { Encoding, Filesystem, FilesystemDirectory } from "@capacitor/filesystem";
import { FileOpener, FileOpenerOptions } from '@capacitor-community/file-opener';
import { Device } from "@capacitor/device";
import { MessagesAlerts, constants } from '@common/index';
import { IErrorResponse } from '@interfaces/*';
import { Camera } from "@capacitor/camera";


@Injectable({
  providedIn: 'root',
})
export class UtilsFunctions {

  loadingPage: any;
  infoDevice: any;
  modal: any;

  errors = [
    { type: 'required', message: "El campo no puede estar vacio." },
    { type: 'pattern', message: "Debe tener un formato correcto." },
    { type: 'minlength', message: "Debe contener al menos 8 caracteres." },
    { type: 'email', message: "Debe tener un formato correcto de correo" },
  ];


  constructor(
    private loadingController: LoadingController,
    private toastController: ToastController,
    private modalController: ModalController,
    private messagesAlerts: MessagesAlerts,
    private PopoverController: PopoverController,
    private location: Location,
    private _localStorage: LocalStorageService,
  ) {
    this.infoDevice = this.logDeviceInfo();
  }

  ionViewWillEnter() {
    this.logDeviceInfo();
  }

  async presentToast(message: string, color: string, duration: number = 1500, position: 'top' | 'middle' | 'bottom' = 'top') {
    if (!message || (message && message.length == 0)) return 0;
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      color: color,
      position: color == 'danger' ? 'bottom' : position,
    });

    await toast.present();
    return toast;
  }

  async presentLoading() {
    this.loadingPage = await this.loadingController.create({
      message: 'Cargando...',
      duration: 100000
    });
    await this.loadingPage.present();
  }

  async stopLoading() {
    await this.loadingPage.dismiss();
  }

  goBack() {
    this.location.back();
  }

  async closeModal() {
    if (this.modal) await this.modal.dismiss();
  }

  async notifyModal(
    component?: any,
    props?: any,
    functionAction?: any,
    animated?: any,
    showBackdrop?: any,
    backdropDismiss?: any,
    cssClass?: string,
    mode?: any,
    keyboardClose?: any
  ) {

    let propCreate: any = {
      component: component,
      componentProps: props,
      animated: animated ? animated : true,
      showBackdrop: showBackdrop ? showBackdrop : true,
      backdropDismiss: backdropDismiss ? backdropDismiss : true,
      cssClass: cssClass ? cssClass : "content-modal-notify",
      mode: mode ? mode : "md",
      keyboardClose: keyboardClose ? keyboardClose : true,
    }

    if (functionAction) {
      propCreate.canDismiss = async (data: any, role: any) => {

        if (!data) return true;

        if (role === 'action') {
          const status = await functionAction();

          if (status == false) return false;
          return true;
        } else return true
      }
    }

    this.modal = await this.modalController.create(propCreate);

    await this.modal.present();

    if (!functionAction) {
      setTimeout(async () => {
        await this.modal.dismiss();
      }, 3000);
    }
  }

  getAllowedPermissions(rolesList: any) {
    let allowedPermissions: any = [];
    rolesList.map((item: any) => {
      item.role.permissions.map((p: any) => {
        allowedPermissions.push(p.permission.name);
      });
    });
    return allowedPermissions;
  }

  toBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result ? reader.result.toString() : '');
      reader.onerror = (error) => reject(error);
    });
  }


  toBase64Mobile(file: File) {
    return new Observable<string>((observer) => {
      const reader = new FileReader();
      const newfile = (reader as any)._realReader;
      newfile.onloadend = async () => {
        const base64Data = newfile.result ? newfile.result.toString() : '';
        observer.next(base64Data);
        observer.complete();
      };
      newfile.readAsDataURL(file);
      return newfile.result;
    });
  }

  stripHtml(html: any) {
    return html.replace(/<[^>]*>?/gm, '');
  }

  manageCsVOrExcelFile(data: any, fileName: string, type: string) {
    const blob = new Blob([data], { type: type === Formats.CSV ? 'text/csv' : 'application/octet-stream' });
    const filePath = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = filePath;
    downloadLink.setAttribute('download', fileName + `.${type}`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  manageCsVOrExcelFileApp(data: any, fileName: string, type: string) {
    const mineType = data.type;
    this.convertBlobToBase64(data, fileName + '.' + type, mineType);
  }

  convertBlobToBase64(blob: Blob, fileName: string, mineType: string) {
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = (event: any) => {
      var base64 = event.target.result;
    };
  }

  /**
 * Saves a base64 encoded file to the specified directory with the given filename and type.
 *
 * @param {any} base64 - The base64 encoded file data.
 * @param {string} fileName - The name of the file to be saved.
 * @param {string} type - The type of the file.
 * @return {Promise<string>} A promise that resolves with the URI of the saved file.
 */
  saveBase64(base64: any, fileName: string, type: string) {
    return Filesystem.writeFile({
      path: fileName,
      data: base64,
      directory: FilesystemDirectory.Documents,
      encoding: Encoding.UTF8,
      recursive: true
    }).then((res) => {
      return Filesystem.getUri({
        directory: FilesystemDirectory.Documents,
        path: fileName
      }).then((uri) => {
        // this._openFileWithType(uri.uri, type);
        return uri.uri;
      })
    });
  }

  async _openFileWithType(filePath: string, fileType: string) {
    const fileOpenerOptions: FileOpenerOptions = {
      filePath: filePath,
      contentType: fileType,
    };

    await FileOpener.open(fileOpenerOptions)
      .then((file) => {
      })
      .catch((error) => {
      });
  }

  errorText(errors: ValidationErrors | null, inputTypeNumber: boolean = false, dualControl: boolean = false): string {
    if (errors) {
      return Object.entries(errors).map(
        ([key, value]) => {
          switch (key) {
            case "required": {
              if (inputTypeNumber || dualControl) {
                if (inputTypeNumber) return "Debe contener un valor solo númerico";
                if (dualControl) return "Los campos no pueden estar vacíos";
              }
              return "El campo no puede estar vacío";
            };
            case "pattern": return "Debe tener un formato correcto";
            case "integersOnly": return "Debe ser solo un número entero";
            case "minPatternDecimal": return `Debe tener mínimo ${value.decimalLength} decimales`;
            case "maxPatternDecimal": return `Debe tener máximo ${value.decimalLength} decimales`;
            case "min": return `Debe ser mayor o igual a ${value.min}`;
            case "max": return `Debe ser menor o igual a ${value.max}`;
            case "minlength": return `Debe contener al menos ${value.requiredLength} caracteres`;
            case "maxlength": return `Máximo ${value.requiredLength} caracteres`;
            case "email": return "Debe tener un formato correcto de correo";
            case "passwordStrength": return value;
            case "confirm": return "La contraseña no coincide";
            case "checkboxesMinimunRequired": return `Mínimo ${value.requiredCheckboxes} ${value.requiredCheckboxes > 1 ? 'opciones seleccionadas' : 'opción seleccionada'}`;
            case "dual":
              return value.control.map(
                (values: any) =>
                  Object.entries(values.error).map(
                    ([dualKey, dualValue]: any) => {
                      switch (dualKey) {
                        case "required": return `El campo ${values.name} no puede estar vacío`;
                        case "min": return `El campo ${values.name} debe ser mayor o igual a ${dualValue.min}`;
                        default: return "Error";
                      }
                    }
                  )
              ).join(", ")
            default: return "Error";
          }
        }
      ).join(", ");
    }
    return " ";
  }

  isJson(item: any) {
    let value = typeof item !== "string" ? JSON.stringify(item) : item;
    try { value = JSON.parse(value); }
    catch (e) { return false; }
    return typeof value === "object" && value !== null;
  }


  thisUserIsAdmin(): boolean | null {
    return this.verifyRoleUser(TypePropertyUser.ADMINISTRADOR)
  }

  thisUserIsProperty(): boolean | null {
    return this.verifyRoleUser(TypePropertyUser.PROPIETARIO)
  }

  thisUserIsTenant(): boolean | null {
    return this.verifyRoleUser(TypePropertyUser.INQUILINO)
  }

  requestPermissionsPhotoVideo(): Promise<any> {
    // if (!this.infoDevice.platform.includes('web') && !this.infoDevice.platform.includes('virtual') && !this.infoDevice.platform.includes('desktop')) {

    return Camera.requestPermissions();

    // } 
  }

  verifyMobile() {
    if (!this.infoDevice.platform.includes('web') && !this.infoDevice.platform.includes('virtual') && !this.infoDevice.platform.includes('desktop')) return true;
    else return false;
  }

  async statusPermissionsPhotoVideo(): Promise<any> {
    await Camera.checkPermissions();
  }

  async checkPermissionsPhotoVideo(): Promise<any> {
    // if (!this.infoDevice.platform.includes('web') && !this.infoDevice.platform.includes('virtual') && !this.infoDevice.platform.includes('desktop')) {

    await Camera.checkPermissions();
  }

  onError(error: IErrorResponse) { this.messagesAlerts.openToast(error.message, "danger", 1500, 'bottom'); }

  private verifyRoleUser(role: any) {
    if (this._localStorage.getItem('userSession') &&
      this._localStorage.getItem('userSession').data &&
      this._localStorage.getItem('userSession').data.infoClientSelected &&
      this._localStorage.getItem('userSession').data.infoClientSelected.role
    ) {
      return this._localStorage.getItem('userSession')?.data?.infoClientSelected?.role?.filter((el: any) => el.propertyUserRoleId === role).length > 0 ? true : false;
    } else return null;
  }

  getRolesUser() {
    if (this._localStorage.getItem('userSession') &&
      this._localStorage.getItem('userSession').data &&
      this._localStorage.getItem('userSession').data.infoClientSelected &&
      this._localStorage.getItem('userSession').data.infoClientSelected.role
    ) {
      return this._localStorage.getItem('userSession').data.infoClientSelected.role.map((x: any) => x.propertyUserRoleId);
    }
  }


  private logDeviceInfo = async () => {
    this.infoDevice = await Device.getInfo();
  };

  isMobil() {
    return this.infoDevice.platform.includes('ios') || this.infoDevice.platform.includes('android');
  }
}
