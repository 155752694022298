import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { environment } from '@environments/environment';
import { first } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class OrderPurchaseService {

  private API_URI: string;

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  getConfigurationDetail(Module: ModuleBackend, data: any) {
    return this.http.post(
      `${this.API_URI}/${Module}/generate_module_config_form`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  getAccountByAuxiliary(Module: ModuleBackend, data: any){
    return this.http.post(
      `${this.API_URI}/${Module}/find_all_accounts_by_type_auxiliary`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  getAccountingDocumentsType(Module: ModuleBackend){
    return this.http.get(
      `${this.API_URI}/${Module}?page=1&skip=1000000`,
      this.headerComplement.complementHeader()
    );
  }

  getExchangeRateBCV(Module: ModuleBackend){
    return this.http.get(
      `${this.API_URI}/${Module}/currency/bcv`,
      this.headerComplement.complementHeader()
    );
  }

  saveVoucher(Module: ModuleBackend, data: any){
    console.log("que se manda?? ", data)
    // if(data.voucherFile.name.length > 0){
    //   const formData: FormData = new FormData();
    //   formData.append('file', data.voucherFile.file);
    //   data.voucherFile = formData
    //   return this.http.post<any>(
    //     `${this.API_URI}/${Module}/create_with_config`, 
    //     data,
    //     { headers: this.headerComplement.getHeadersUploadFile() }
    //   ).pipe(first());
    // } else {
      return this.http.post(
        `${this.API_URI}/${Module}/create_with_config`,
        data,
        this.headerComplement.complementHeader()
      );
    // }
  }

  getDetailVoucherById(Module: ModuleBackend, voucherId: number){
    return this.http.post(
      `${this.API_URI}/${Module}/voucher_module_config_form`,
      {orderId: voucherId},
      this.headerComplement.complementHeader()
    );
  }

  getAllProducts(Module: ModuleBackend, withoutModuleGroup?: boolean){
    return this.http.get(
      `${this.API_URI}/${Module}?page=1&skip=10000000`,
      this.headerComplement.complementHeader()
    );
  }

  getFileVoucher(Module: ModuleBackend, voucherId: number){
    return this.http.post(
      `${this.API_URI}/${Module}/get_file`,
      {orderId: Number(voucherId)},
      this.headerComplement.complementHeader()
    );
  }

  validateDate(Module : ModuleBackend, data: any){
    return this.http.post(
      `${this.API_URI}/${Module}/validate_date`,
      data,
      this.headerComplement.complementHeader()
    );
  }
}
