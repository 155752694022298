import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, first, Observable } from 'rxjs';
import { ModuleBackend } from '@enums/index';
import { ICrudResponse, IEconomicActivity, IFilter, IInventoryLots, ILevel, ILot, IPagination, IProduct, IProductPresentation, IResponse, IUnitMeasure } from '@interfaces/index';
import { GenericCrudService } from '@common/classes/generic-crud.service';
import { TableTypesService } from '@services/common';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ProductsInventoryService extends GenericCrudService<IProduct> {
  readonly filterObj: IFilter[] = [];
  private productSelected$: BehaviorSubject<IProduct>;
  private showEdit$: BehaviorSubject<boolean>;

  constructor(private tableTypesService: TableTypesService) {
    super(ModuleBackend.PRODUCT);
    this.productSelected$ = new BehaviorSubject(null);
    this.showEdit$ = new BehaviorSubject(false);
  }

  override create(data: Partial<IProduct>): Observable<IResponse<IProduct>> { return super.create(data); }
  override readOne(id: number): Observable<IResponse<IProduct>> { return super.readOne(id); }
  override readAll(pagination: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {} | {}[]): Observable<ICrudResponse<IProduct>> { return super.readAll(pagination, extraKeys); }
  readAllLevel(): Observable<Pick<ILevel, "id" | "name">[]> { return this.tableTypesService.readAllLevel(); }
  readAllUnitMeasure(): Observable<Pick<IUnitMeasure, "id" | "name">[]> { return this.tableTypesService.readAllUnitMeasure(); }
  readAllProductPresentation(pagination: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {} | {}[]): Observable<ICrudResponse<IProductPresentation>> {
    const filterKeys = this.filterKeys(extraKeys);
    return this.http.get<ICrudResponse<IProductPresentation>>(`
      ${environment.API_URL}/${ModuleBackend.PRODUCT_PRESENTATION}?page=${pagination.currentPage}&skip=${pagination.skip}${filterKeys}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }
  readAllLot(pagination: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {} | {}[]): Observable<ICrudResponse<ILot>> {
    const filterKeys = this.filterKeys(extraKeys);
    return this.http.get<ICrudResponse<ILot>>(`
      ${environment.API_URL}/${ModuleBackend.LOT}?page=${pagination.currentPage}&skip=${pagination.skip}${filterKeys}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }
  readAllLotInventory(pagination: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {} | {}[]): Observable<ICrudResponse<IInventoryLots & IProduct>> {
    const filterKeys = this.filterKeys(extraKeys);
    return this.http.get<ICrudResponse<IInventoryLots & IProduct>>(`
      ${environment.API_URL}/${ModuleBackend.LOT}/inventory/product?page=${pagination.currentPage}&skip=${pagination.skip}${filterKeys}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }
  readAllEconomicActivity(pagination: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {} | {}[]): Observable<ICrudResponse<IEconomicActivity>> {
    const filterKeys = this.filterKeys(extraKeys);
    return this.http.get<ICrudResponse<IEconomicActivity>>(`
      ${environment.API_URL}/${ModuleBackend.ECONOMIC_ACTIVITY}?page=${pagination.currentPage}&skip=${pagination.skip}${filterKeys}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }
  override update(id: number, data: Partial<IProduct>): Observable<IResponse<IProduct>> { return super.update(id, data); }
  override delete(id: number): Observable<IResponse<IProduct>> { return super.delete(id); }

  setProduct(product: IProduct) { this.productSelected$.next(product); }
  getProduct() { return this.productSelected$.getValue(); }
  setshowEdit(show: boolean = false) { this.showEdit$.next(show); }
  getshowEdit() { return this.showEdit$.getValue(); }
}
