import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModuleBackend } from '@enums/index';
import { ICrudResponse, IFilter, IPagination, IResponse, IVoucher } from '@interfaces/index';
import { GenericCrudService } from '@common/classes/generic-crud.service';


@Injectable({
  providedIn: 'root'
})
export class VoucherService extends GenericCrudService<IVoucher> {
  readonly filterObj: IFilter[] = [
    {
      label: "Número de comprobante",
      controlName: "numberVoucher",
      type: "text"
    },
    {
      label: "Monto referencial",
      controlName: "totalVoucherRef",
      type: "amount"
    }
  ];

  constructor() { super(ModuleBackend.VOUCHER_CLIENT); }

  override readOne(id: number): Observable<IResponse<IVoucher>> { return super.readOne(id); }
  override readAll(pagination: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {} | {}[]): Observable<ICrudResponse<IVoucher>> { return super.readAll(pagination, extraKeys); }
}
