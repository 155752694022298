import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpComplement } from '@common/functions/http-complement';
import { Observable } from 'rxjs';
import { UrlTree } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ClientService {

  private API_URI: string;

  constructor(
    private http: HttpClient,
    private headerComplement: HttpComplement
  ) {
    this.API_URI = environment.API_URL;
  }

  registerClient(Module: ModuleBackend, data: any){
    return this.http.post(
        `${this.API_URI}/${Module}`,
        data
    );
  }

  getAllCLients(Module: ModuleBackend, pagination: any, filter?: any){
    return this.http.get(
      `${this.API_URI}/${Module}?page=${pagination.page}&skip=${pagination.skip}`,
      this.headerComplement.complementHeader()
    );
  }

  getClient(Module: ModuleBackend, id: any){
    return this.http.get(
      `${this.API_URI}/${Module}/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  deleteClient(Module: ModuleBackend, id: any){
    return this.http.delete(
      `${this.API_URI}/${Module}/${id}`,
      this.headerComplement.complementHeader()
    );
  }

  updateClient(Module: ModuleBackend, data: any, id: any){
    return this.http.patch(
      `${this.API_URI}/${Module}/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  updateClientCompany(Module: ModuleBackend, data: any, id: any){
    return this.http.patch(
      `${this.API_URI}/${Module}/update/client/${id}`,
      data,
      this.headerComplement.complementHeader()
    );
  }
}
