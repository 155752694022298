import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavigationApp } from 'src/navegation';

@Injectable({
  providedIn: 'root'
})
export class CheckLoginGuard implements CanActivate {

  constructor(
    public router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {

    if (localStorage.getItem('userSession') != null) {
      if (localStorage.getItem('userSession') != null) this.router.navigate([NavigationApp.DASHBOARD + '/' + NavigationApp.HOME]);
      return false;
    }
  }
}
