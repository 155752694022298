import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-upload-bulk',
  templateUrl: './modal-upload-bulk.component.html',
  styleUrls: ['./modal-upload-bulk.component.scss'],
})
export class ModalUploadBulkComponent  implements OnInit {

  file: any;

  @Input() title:  string = '';
  @Input() detailElement: string = '';
  @Input() action: string = '';

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {}

  changeFile(event: any){
    this.file = event;
  }

  btnAction(){
    this.modalCtrl.dismiss({role: true, file: this.file});
  }

  cancel(){
    this.modalCtrl.dismiss(false);
  }

}
