import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, first, map, Observable, pairwise, startWith } from 'rxjs';
import { ModuleBackend, TypeInvoiceRequestList } from '@enums/index';
import { GenericCrudService } from '@common/classes/generic-crud.service';
import { IPagination, ICrudResponse, IResponse, IInvoiceRequest } from '@interfaces/*';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class InvoiceRequestService extends GenericCrudService<IInvoiceRequest> {
  private typeInvoiceRequestList$: BehaviorSubject<TypeInvoiceRequestList>;
  private requestSelected$: BehaviorSubject<IInvoiceRequest>;

  constructor() {
    super(ModuleBackend.INVOICE_REQUEST);
    this.typeInvoiceRequestList$ = new BehaviorSubject(TypeInvoiceRequestList.PENDING);
    this.requestSelected$ = new BehaviorSubject(null);
  }

  override create(data: Partial<IInvoiceRequest>): Observable<IResponse<IInvoiceRequest>> { return super.create(data); }
  override readOne(id: number): Observable<IResponse<IInvoiceRequest>> { return super.readOne(id); }
  override readAll(pagination: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {} | {}[]): Observable<ICrudResponse<IInvoiceRequest>> {
    return super.readAll({ ...pagination, perPage: pagination.skip, skip: undefined }, extraKeys);
  }
  override update(id: number, data: Partial<IInvoiceRequest>): Observable<IResponse<IInvoiceRequest>> { return super.update(id, data); }
  updateStatus(id: number, data: { status: TypeInvoiceRequestList, description: string }): Observable<IResponse<IInvoiceRequest>> {
    return this.http.patch<IResponse<IInvoiceRequest>>(`
    ${environment.API_URL}/${ModuleBackend.INVOICE_REQUEST}/${id}/chage-status`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  setTypeInvoiceRequestList(type: TypeInvoiceRequestList) { this.typeInvoiceRequestList$.next(type); }
  getTypeInvoiceRequestList() { return this.typeInvoiceRequestList$.getValue(); }
  getTypeInvoiceRequestListObservable(): Observable<{ previousState: TypeInvoiceRequestList; currentState: TypeInvoiceRequestList; }> {
    return this.typeInvoiceRequestList$.asObservable()
      .pipe(
        startWith(TypeInvoiceRequestList.NONE),
        pairwise(),
        map(([previousState, currentState]) => ({ previousState, currentState }))
      );
  }
  setRequest(request: IInvoiceRequest) { this.requestSelected$.next(request); }
  getRequest() { return this.requestSelected$.getValue(); }
}
